import React, { useState } from 'react';
import { dataGeoJson } from '../../data/geojson/Municipios';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import clienteAxios from '../../../config/clientAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const FichasMuncipales = () => {
    const route = 'publicaciones/fichas_municipales/';
    const municipios = [
        { id: 1, file: '001_Apozol.pdf', nombre: 'Apozol', color: '#D6F7CD' },
        { id: 2, file: '002_Apulco.pdf', nombre: 'Apulco', color: '#FFA77F' },
        { id: 3, file: '003_Atolinga.pdf', nombre: 'Atolinga', color: '#E2C9F2' },
        { id: 4, file: '004_Benito_Juarez.pdf', nombre: 'Benito Juárez', color: '#FFA77F' },
        { id: 5, file: '005_Calera.pdf', nombre: 'Calera', color: '#F7F2D2' },
        { id: 6, file: '006_Canitas_de_Felipe_Pescador.pdf', nombre: 'Cañitas de Felipe Pescador', color: '#70A800' },
        { id: 7, file: '007_Concepcion_del_Oro.pdf', nombre: 'Concepción del Oro', color: '#E6E600' },
        { id: 8, file: '008_Cuauhtemoc.pdf', nombre: 'Cuauhtémoc', color: '#73B2FF' },
        { id: 9, file: '009_Chalchihuites.pdf', nombre: 'Chalchihuites', color: '#FFA77F' },
        { id: 10, file: '010_Fresnillo.pdf', nombre: 'Fresnillo', color: '#E6E600' },
        { id: 11, file: '011_Trinidad_Garcia_de_la_Cadena.pdf', nombre: 'Trinidad García de la Cadena', color: '#E2C9F2' },
        { id: 12, file: '012_Genaro_Codina.pdf', nombre: 'Genaro Codina', color: '#FFA77F' },
        { id: 13, file: '013_Gral_Enrique_Estrada.pdf', nombre: 'General Enrique Estrada', color: '#73B2FF' },
        { id: 14, file: '014_General_Francisco_R_Murguia.pdf', nombre: 'General Francisco R. Murguía', color: '#73B2FF' },
        { id: 15, file: '015_El_Plateado.pdf', nombre: 'El Plateado de Joaquín Amaro', color: '#D6F7CD' },
        { id: 16, file: '016_Gral_Panfilo_Natera.pdf', nombre: 'General Pánfilo Natera', color: '#73B2FF' },
        { id: 17, file: '017_Guadalupe.pdf', nombre: 'Guadalupe', color: '#CCCCCC' },
        { id: 18, file: '018_Huanusco.pdf', nombre: 'Huanusco', color: '#70A800' },
        { id: 19, file: '019_Jalpa.pdf', nombre: 'Jalpa', color: '#FF7F7F' },
        { id: 20, file: '020_Jerez.pdf', nombre: 'Jerez', color: '#73B2FF' },
        { id: 21, file: '021_Jimenez_del_Teul.pdf', nombre: 'Jiménez del Teul', color: '#D6F7CD' },
        { id: 22, file: '022_Juan_Aldama.pdf', nombre: 'Juan Aldama', color: '#E6E600' },
        { id: 23, file: '023_Juchipila.pdf', nombre: 'Juchipila', color: '#CCCCCC' },
        { id: 24, file: '024_Loreto.pdf', nombre: 'Loreto', color: '#E6E600' },
        { id: 25, file: '025_Luis_Moya.pdf', nombre: 'Luis Moya', color: '#E2C9F2' },
        { id: 26, file: '026_Mazapil.pdf', nombre: 'Mazapil', color: '#70A800' },
        { id: 27, file: '027_Melchor_Ocampo.pdf', nombre: 'Melchor Ocampo', color: '#73B2FF' },
        { id: 28, file: '028_Mezquital_del_Oro.pdf', nombre: 'Mezquital del Oro', color: '#73B2FF' },
        { id: 29, file: '029_Miguel_Auza.pdf', nombre: 'Miguel Auza', color: '#D6F7CD' },
        { id: 30, file: '030_Momax.pdf', nombre: 'Momax', color: '#73B2FF' },
        { id: 31, file: '031_Monte_Escobedo.pdf', nombre: 'Monte Escobedo', color: '#70A800' },
        { id: 32, file: '032_Morelos.pdf', nombre: 'Morelos', color: '#D6F7CD' },
        { id: 33, file: '33_Moyahua_de_Estrada.pdf', nombre: 'Moyahua de Estrada', color: '#D6F7CD' },
        { id: 34, file: '034_Nochistlan_de_Mejia.pdf', nombre: 'Nochistlán de Mejía', color: '#E6E600' },
        { id: 35, file: '035_Noria_de_Angeles.pdf', nombre: 'Noria de Ángeles', color: '#FFA77F' },
        { id: 36, file: '036_Ojocaliente.pdf', nombre: 'Ojocaliente', color: '#70A800' },
        { id: 37, file: '037_Panuco.pdf', nombre: 'Pánuco', color: '#BFECF5' },
        { id: 38, file: '038_Pinos.pdf', nombre: 'Pinos', color: '#CCCCCC' },
        { id: 39, file: '039_Rio_Grande.pdf', nombre: 'Río Grande', color: '#CCCCCC' },
        { id: 40, file: '040_Sain_Alto.pdf', nombre: 'Sain Alto', color: '#F7F2D2' },
        { id: 41, file: '041_El_Salvador.pdf', nombre: 'El Salvador', color: '#FFA77F' },
        { id: 42, file: '042_Sombrerete.pdf', nombre: 'Sombrerete', color: '#70A800' },
        { id: 43, file: '043_Susticacan.pdf', nombre: 'Susticacán', color: '#F7F2D2' },
        { id: 44, file: '044_Tabasco.pdf', nombre: 'Tabasco', color: '#E6E600' },
        { id: 45, file: '045_Tepechitlan.pdf', nombre: 'Tepechitlán', color: '#73B2FF' },
        { id: 46, file: '046_Tepetongo.pdf', nombre: 'Tepetongo', color: '#BFECF5' },
        { id: 47, file: '047_Teul_de_Gonzalez_Ortega.pdf', nombre: 'Teúl de González Ortega', color: '#E6E600' },
        { id: 48, file: '048_Tlaltenago_de_Sanchez_Roman.pdf', nombre: 'Tlaltenango de Sánchez Román', color: '#CCCCCC' },
        { id: 49, file: '049_Valparaiso.pdf', nombre: 'Valparaíso', color: '#E2C9F2' },
        { id: 50, file: '050_Vetagrande.pdf', nombre: 'Vetagrande', color: '#E6E600' },
        { id: 51, file: '051_Villa_de_Cos.pdf', nombre: 'Villa de Cos', color: '#FFA77F' },
        { id: 52, file: '052_Villa_Garcia.pdf', nombre: 'Villa García', color: '#D6F7CD' },
        { id: 53, file: '053_Villa_Gonzalez_Ortega.pdf', nombre: 'Villa González Ortega', color: '#D6F7CD' },
        { id: 54, file: '054_Villa_Hidalgo.pdf', nombre: 'Villa Hidalgo', color: '#F7F2D2' },
        { id: 55, file: '055_Villanueva.pdf', nombre: 'Villanueva', color: '#E2C9F2' },
        { id: 56, file: '056_Zacatecas.pdf', nombre: 'Zacatecas', color: '#70A800' },
        { id: 57, file: '057_Trancoso.pdf', nombre: 'Trancoso', color: '#F7F2D2' },
        { id: 58, file: '058_Santa_Maria_de_la_Paz.pdf', nombre: 'Santa María de la Paz', color: '#70A800' }
    ];
    const [municipio, setMunicipio] = useState({});
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(false);

    const downFile = () => {
        setLoading(true);
        const file = `${route}${selected.file}`;
        clienteAxios.post(`https://admin-siem.zacatecas.gob.mx/api/pdf`, { file: file }, {
            responseType: 'blob',
        }).then(get => {
            const url = URL.createObjectURL(new Blob([get.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${selected.file}`
            );
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <h2>Cédula de Información Básica Municipal</h2>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-8'>
                    <div className='row'>
                        <div className="col-md-10" style={{ textAlign: 'center' }}>
                            {selected.id ? <h5><i>Municipio {selected.id}.-{selected.nombre}</i></h5> : <h5>&nbsp;</h5>}
                        </div>
                        {selected.id &&
                            <div className='col-md-2'>
                                <button type='button' className='btn btn-danger btn-sm' onClick={() => downFile()} disabled={loading}>
                                    Descargar <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='mapa'>
                        <MapContainer style={{ "width": "32vw", "height": "82vh" }} center={['22.818146', '-102.458302']} zoom={7} minZoom={6} maxZoom={11} touchZoom={-1} zoomControl={true} scrollWheelZoom={false}>
                            <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                            <div className="leaflet-top leaflet-right">
                                <div className="info leaflet-control text-center" style={{ padding: '6px 8px', font: '14px/16px Arial, Helvetica, sans-serif', background: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.2)', borderRadius: '5px', opacity: 0.7 }}>
                                    <h6 style={{ color: '#777' }}>Zacatecas, México</h6>
                                    {municipio.id &&
                                        <div>
                                            <strong>{municipio.id} - {municipio.nombre}</strong><br></br>
                                            <label>Municipio</label>
                                        </div>
                                    }
                                </div>
                            </div>
                            {dataGeoJson.features.map(dato => {
                                return (
                                    <GeoJSON
                                        key={dato.properties.CVE_MUN}
                                        data={dato}
                                        eventHandlers={{
                                            mouseover: (e) => {
                                                e.layer.setStyle({
                                                    fillOpacity: 1
                                                });
                                                var contentPopUp = `<strong>${parseInt(dato.properties.CVE_MUN)}.-${dato.properties.NOMGEO}</strong>`;
                                                e.propagatedFrom.bindTooltip(contentPopUp).openTooltip();
                                                setMunicipio({
                                                    id: parseInt(dato.properties.CVE_MUN),
                                                    clave: dato.properties.CVEGEO,
                                                    nombre: dato.properties.NOMGEO
                                                });
                                            },
                                            mouseout: (e) => {
                                                setMunicipio({});
                                                e.layer.setStyle({
                                                    fillOpacity: 0.6
                                                });
                                            },
                                            click: (e) => {
                                                setSelected(municipios.filter(r => r.id == parseInt(dato.properties.CVE_MUN))[0]);
                                            },
                                        }}
                                        color={'white'}
                                        fillColor={municipios.filter(r => r.id == parseInt(dato.properties.CVE_MUN))[0].color}
                                        fillOpacity={0.6}
                                        dashArray={'3'}>
                                    </GeoJSON>
                                );
                            })}
                        </MapContainer>
                    </div>
                </div>
                <div className='col-md-8' style={{ textAlign: 'center' }}>
                    {selected.id ?
                        <iframe title={selected.file} src={`https://admin-siem.zacatecas.gob.mx/storage/${route}${selected.file}#toolbar=0`} width="100%" height="100%" frameBorder="1"></iframe> :
                        <div width="100%" height="100%" style={{ marginRight: '1rem', marginTop: '8rem' }}>
                            <h5>
                                <i style={{ color: 'gray' }}>
                                    Documentos compilatorios de información estadística básica de diversas fuentes sobre los siguientes temas:<br/>
                                    <br/>
                                    <strong>1.-</strong> ASPECTOS DEMOGRÁFICOS<br/>
                                    <strong>2.-</strong> INFORMACIÓN ECONÓMICA<br/>
                                    <strong>3.-</strong> CARACTERÍSTICAS EDUCATIVAS<br/>
                                    <strong>4.-</strong> CARACTERÍSTICAS DE SALUD<br/>
                                    <strong>5.-</strong> VÍAS DE COMUNICACIÓN<br/>
                                    <strong>6.-</strong> INFRAESTRUCTURA BÁSICA Y MARGINACIÓN<br/>
                                    <strong>7.-</strong>AGRICULTURA Y GANADERÍA<br/>
                                    <strong>8.-</strong> INFRAESTRUCTURA Y MEDIO AMBIENTE<br/>
                                    <strong>9.-</strong> DELITOS DEL FUERO COMÚN<br/>
                                    <br/>
                                    Presionar sobre el mapa en el municipio de su interés para mostrar la Ficha correspondiente.
                                </i>
                            </h5>
                        </div>
                    }
                </div>
            </div>
            <br />
            <br />
        </React.Fragment>
    );
}

export default FichasMuncipales;