import React from 'react';
import { Link } from 'react-router-dom';
import { municipios } from '../../data/municipios';

const InfoGeoMun = () => {

    const styleEnabled = { background: '#990B0C', color: '#FFFFFF', fontSize: '14', fontWeight: 'bold', cursor: 'pointer' };
    const route = 'publicaciones/informacion_geografica_municipal/';
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Información Geográfica Municipal 2010</h2>
                </div>
            </div>
            <hr />
            {municipios.map((m) => {
                return (
                    <React.Fragment key={m.clave}>
                        <div className='row'>
                            <div className='col-md-11'>
                                <h3>
                                    <Link to={`/pdf/${route}${m.clave}.pdf`} data-tip data-for={`${route}${m.clave}.pdf`} style={{ color: '#000000', textDecoration: 'none' }}>
                                        {m.nombre}
                                    </Link>
                                </h3>
                            </div>
                            <div className='col-md-1'>
                                <div className='col-md-6'></div>
                                <div className='col-md-6 text-center' style={styleEnabled}>
                                    <Link to={`/pdf/${route}${m.clave}.pdf`} data-tip data-for={`${route}${m.clave}.pdf`} style={{ color: '#FFF', textDecoration: 'none' }}>
                                        <span><small> PDF</small></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default InfoGeoMun;