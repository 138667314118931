import React from 'react';
import File from '../../extras/File';

const Pat = () => {
   
    const images = [
        { id: 1, name: 'Programa Anual de Trabajo 2022.', image: 'ceieg/PAT_SEP-DIC_2022.png', file: 'ceieg/PAT_SEP-DIC_2022.pdf', type: 'PDF' },
        { id: 2, name: 'Programa Anual de Trabajo 2023.', image: 'ceieg/Programa__de_Anual_de_Trabajo_2023.png', file: 'ceieg/Programa__de_Anual_de_Trabajo_2023.pdf', type: 'PDF' },
        { id: 3, name: 'Programa Anual de Trabajo 2024.', image: 'ceieg/Programa-Anual-de-Trabajo-2024.png', file: 'ceieg/Programa-Anual-de-Trabajo-2024.pdf', type: 'PDF' }
    ];

     return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Programa Anual de Trabajo</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}
export default Pat;