import React from 'react';
import File from '../../../extras/File';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ITAEE2021 = () => {

    const navigate = useNavigate();

    const images = [
        { id: 1, name: '1 TRIM 2021', image: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_1TRIM_2021.png', file: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_1TRIM_2021.pdf', type: 'PDF' },
        { id: 2, name: '2 TRIM 2021', image: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_2TRIM_2021.png', file: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_2TRIM_2021.pdf', type: 'PDF' },
        { id: 3, name: '3 TRIM 2021', image: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_3TRIM_2021.png', file: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_3TRIM_2021.pdf', type: 'PDF' },
        { id: 4, name: '4 TRIM 2021', image: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_4TRIM_2021.png', file: 'publicaciones/boletines_inegi/ITAEE_2021/ACT_ECO_ZAC_4TRIM_2021.pdf', type: 'PDF' }
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>ENCUESTA NACIONAL DE OCUPACIÓN Y EMPLEO 2021</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}

export default ITAEE2021;