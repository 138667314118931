import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">CIEG</Navbar.Brand>
                <Navbar.Brand>|</Navbar.Brand>
                <Navbar.Brand href="#ZacEnCifras">Zacatecas en Cifras</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to='/'>Inicio</Nav.Link>
                        <Nav.Link as={Link} to="presentacion">Presentación</Nav.Link>
                        <Nav.Link as={Link} to="marco-juridico">Marco Jurídico</Nav.Link>
                        <NavDropdown title="Normatividad" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to='/normatividad/estadistica'>Normas Técnicas Estadísticas</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/normatividad/geografica'>Normas Técnicas Geográficas</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="CEIEG" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to='/ceieg/integrantes'>Integrantes del CEIEG</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/ceieg/sesiones_ordinarias'>Sesiones ordinarias</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/ceieg/peeg'>PEEG 2022-20227</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/ceieg/pat'>Programa Anuales de Trabajo</NavDropdown.Item>
                            { /* <NavDropdown.Divider /> */ }
                        </NavDropdown>
                        <NavDropdown title="Publicaciones" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to='/publicaciones/coepo'>Consejo Estatal de Población (COEPO)</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/revistas'>Revista: Espacio, realidad y datos</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/revista'>Revista: Espacio, realidad y datos (septiembre-diciembre 2022)</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/mapas_tematicos'>Mapas Temáticos e Infografías</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/fichas_regionales'>Cédula de información básica regional</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/fichas_municipales'>Cédula de información básica municipal</NavDropdown.Item>
                            
                            <NavDropdown.Item as={Link} to='/publicaciones/zap_2022'>Zonas de Atención Prioritaria(PDF) 2022 </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/zap_2023'>Zonas de Atención Prioritaria(PDF) 2023 </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/zap_2024'>Zonas de Atención Prioritaria(PDF) 2024 </NavDropdown.Item>

                            <NavDropdown.Item as={Link} to='/publicaciones/croquis_municipales'>Croquis Municipales</NavDropdown.Item>

                            <NavDropdown.Item as={Link} to='/publicaciones/informacion_geografica_regional'>Información Geográfica Regional</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/informacion_geografica_municipal'>Información Geográfica Municipal 2010</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/panorama-sociodemografico'>Panorama Sociodemográfico Zacatecas 2015</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/panorama-sociodemografico-2020'>Panorama Sociodemográfico Zacatecas 2020</NavDropdown.Item>
                            
                            <NavDropdown.Item as={Link} to='/publicaciones/documentos_analisis'>Documentos análisis</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to='/publicaciones/boletines_inegi'>Boletines INEGI</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        <Nav.Link href="#home">Contacto</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default Header;