import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../../assets/css/Zoom.css';

const Sistemas = () => {
    return (
        <div className='shadow p-3 mb-3 bg-white rounded' style={{ marginBottom: `1.45rem`, }}>
            <Container>
                <Row><h2 align='center'><font color='#7D878E'><strong>Micrositios</strong></font></h2></Row>
                <Row>
                    <Col align='center'>
                        <a className="nav-link link" target="_blank" href="http://sige.zacatecas.gob.mx" rel="noopener noreferrer" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4>SIGE ZACATECAS</h4>
                            <img src={`/images/zacatecas-en-cifras/mapa.png`} width='150' height='150'
                                alt='Servicio de Información georreferenciada' className='zoom-sector' />
                            <p><small>Servicio de Información georreferenciada</small></p>
                        </a>
                    </Col>
                    <Col align='center'>
                        <a className="nav-link link" target="_blank" href="https://siem.zacatecas.gob.mx" rel="noopener noreferrer" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4>SIEM</h4>
                            <img src={`/images/zacatecas-en-cifras/estadistica.png`} width='150' height='150'
                                alt='Sistema de consulta de información estadística<' className='zoom-sector' />
                            <p><small>Sistema de información estadística municipal </small></p>
                        </a>
                    </Col>
                    {/*
                    <Col align='center'>
                        <Link to="http://siem.zacatecas.gob.mx" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 style={{ marginBottom: '1rem' }}>Proyectos CEIEG</h4>
                            <img src={`/images/zacatecas-en-cifras/proyectos.png`} width='150' height='150'
                                alt='Proyectos CEIEG' className='zoom-sector' />
                        </Link>
                    </Col>
                    <Col align='center'>
                        <h4 style={{ color: `#7D878E`, textDecoration: `none`, marginBottom: '1rem' }}>Cédulas de información básica</h4>
                        <img src={`/images/zacatecas-en-cifras/zacatecas.png`} width='150' height='150' alt='Cédulas de información básica' className='zoom-sector' />
                    </Col>
                    */}
                </Row>
            </Container>
        </div>
    );
}
export default Sistemas;