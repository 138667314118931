import React from 'react';
import File from '../../extras/File';

const Coepo = () => {
    
    const images = [
        { id: 1, name: 'Comparativo de las principales causas de muerte  jovenes de 12 a 29 años.', image: 'publicaciones/coepo/comparativa_muertes.png', file: 'publicaciones/coepo/COMPARATIVO-DE-LAS-PRINCIPALES-CAUSAS-DE-MUERTE-EN-JOVENES-v3.pdf', type: 'PDF' },
        { id: 2, name: 'Indicadores sociodemográficos en Zacatecas de la población de 60 años y más. ', image: 'publicaciones/coepo/indicadores_sociodemograficos.png', file: 'publicaciones/coepo/INDICADORES-SOCIODEMOGRAFICOS-DE-LA-POBLACION-DE-60-ANOS-Y-MAS_zac.pdf', type: 'PDF' },
        { id: 3, name: 'Proyecciones de población 2015-2030 Zacatecas.', image: 'publicaciones/coepo/proyeccion_poblacion.png', file: 'publicaciones/coepo/Proyecciones-d-poblacion-Zacatecas-2015-2030-1.xlsx', type: 'XLS' },
        { id: 4, name: 'Personas desaparecidas y no localizadas en Zacatecas.', image: 'publicaciones/coepo/personas_desaparecidas.png', file: 'publicaciones/coepo/PERSONAS-DESAPARECIDAS-Y-NO-LOCALIZADAS-EN-ZACATECAS.pdf', type: 'PDF' },
        { id: 5, name: 'Principales cifras Proyecciones de Población Zacatecas 2020-2070', image: 'publicaciones/coepo/Principales-cifras-Proyecciones-de-Pob-Zacatecas-2020-2070.png', file: 'publicaciones/coepo/Principales-cifras-Proyecciones-de-Pob-Zacatecas-2020-2070.pdf', type: 'PDF' },
        { id: 6, name: 'Personas desaparecidas y no localizadas en Zacatecas al 16 Enero 2024', image: 'publicaciones/coepo/PERSONAS-DESAPARECIDAS-Y-NO-LOCALIZADAS-EN-ZACATECAS-actualizado-al-16-de-enero-24.png', file: 'publicaciones/coepo/PERSONAS-DESAPARECIDAS-Y-NO-LOCALIZADAS-EN-ZACATECAS-actualizado-al-16-de-enero-24.pdf', type: 'PDF' },
        { id: 7, name: 'Indicadores demográficos diversos de la reconstrucción y proyecciones de la población 1990-2040 por municipio', image: 'publicaciones/coepo/Indicadores_demograficos_diversos.png', file: 'publicaciones/coepo/Indicadores_demograficos_diversos.xlsx', type: 'XLS' },
        { id: 8, name: 'Reconstrucción y proyecciones de la población 1990-2040 por municipio', image: 'publicaciones/coepo/Poblacion_por_grupo_quinquenal_de_edad.png', file: 'publicaciones/coepo/Poblacion_por_grupo_quinquenal_de_edad.xlsx', type: 'XLS' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Consejo Estatal de Población (COEPO)</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Coepo;