import React, { useState } from 'react';
import { dataGeoJson } from './../../data/geojson/Zap';
//import { dataGeoJson as GeoJsonMunicipios } from '../../data/geojson/Municipios';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import clienteAxios from '../../../config/clientAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const FichasZap = () => {
    const route = 'publicaciones/fichas_municipales/';
    const municipios = [
        { id: 1, file: '32001.pdf', nombre: 'Apozol', color: '#D6F7CD' },
        { id: 2, file: '32002.pdf', nombre: 'Apulco', color: '#FFA77F' },
        { id: 3, file: '32003.pdf', nombre: 'Atolinga', color: '#E2C9F2' },
        { id: 4, file: '32004.pdf', nombre: 'Benito Juárez', color: '#FFA77F' },
        { id: 5, file: '32005.pdf', nombre: 'Calera', color: '#F7F2D2' },
        { id: 6, file: '32006.pdf', nombre: 'Cañitas de Felipe Pescador', color: '#70A800' },
        { id: 7, file: '32007.pdf', nombre: 'Concepción del Oro', color: '#E6E600' },
        { id: 8, file: '32008.pdf', nombre: 'Cuauhtémoc', color: '#73B2FF' },
        { id: 9, file: '32009.pdf', nombre: 'Chalchihuites', color: '#FFA77F' },
        { id: 10, file: '32010.pdf', nombre: 'Fresnillo', color: '#E6E600' },
        { id: 11, file: '32011.pdf', nombre: 'Trinidad García de la Cadena', color: '#E2C9F2' },
        { id: 12, file: '32012.pdf', nombre: 'Genaro Codina', color: '#FFA77F' },
        { id: 13, file: '32013.pdf', nombre: 'General Enrique Estrada', color: '#73B2FF' },
        { id: 14, file: '32014.pdf', nombre: 'General Francisco R. Murguía', color: '#73B2FF' },
        { id: 15, file: '32015.pdf', nombre: 'El Plateado de Joaquín Amaro', color: '#D6F7CD' },
        { id: 16, file: '32016.pdf', nombre: 'General Pánfilo Natera', color: '#73B2FF' },
        { id: 17, file: '32017.pdf', nombre: 'Guadalupe', color: '#CCCCCC' },
        { id: 18, file: '32018.pdf', nombre: 'Huanusco', color: '#70A800' },
        { id: 19, file: '32019.pdf', nombre: 'Jalpa', color: '#FF7F7F' },
        { id: 20, file: '32020.pdf', nombre: 'Jerez', color: '#73B2FF' },
        { id: 21, file: '32021.pdf', nombre: 'Jiménez del Teul', color: '#D6F7CD' },
        { id: 22, file: '32022.pdf', nombre: 'Juan Aldama', color: '#E6E600' },
        { id: 23, file: '32023.pdf', nombre: 'Juchipila', color: '#CCCCCC' },
        { id: 24, file: '32024.pdf', nombre: 'Loreto', color: '#E6E600' },
        { id: 25, file: '32025.pdf', nombre: 'Luis Moya', color: '#E2C9F2' },
        { id: 26, file: '32026.pdf', nombre: 'Mazapil', color: '#70A800' },
        { id: 27, file: '32027.pdf', nombre: 'Melchor Ocampo', color: '#73B2FF' },
        { id: 28, file: '32028.pdf', nombre: 'Mezquital del Oro', color: '#73B2FF' },
        { id: 29, file: '32029.pdf', nombre: 'Miguel Auza', color: '#D6F7CD' },
        { id: 30, file: '32030.pdf', nombre: 'Momax', color: '#73B2FF' },
        { id: 31, file: '32031.pdf', nombre: 'Monte Escobedo', color: '#70A800' },
        { id: 32, file: '32032.pdf', nombre: 'Morelos', color: '#D6F7CD' },
        { id: 33, file: '32033.pdf', nombre: 'Moyahua de Estrada', color: '#D6F7CD' },
        { id: 34, file: '32034.pdf', nombre: 'Nochistlán de Mejía', color: '#E6E600' },
        { id: 35, file: '32035.pdf', nombre: 'Noria de Ángeles', color: '#FFA77F' },
        { id: 36, file: '32036.pdf', nombre: 'Ojocaliente', color: '#70A800' },
        { id: 37, file: '32037.pdf', nombre: 'Pánuco', color: '#BFECF5' },
        { id: 38, file: '32038.pdf', nombre: 'Pinos', color: '#CCCCCC' },
        { id: 39, file: '32039.pdf', nombre: 'Río Grande', color: '#CCCCCC' },
        { id: 40, file: '32040.pdf', nombre: 'Sain Alto', color: '#F7F2D2' },
        { id: 41, file: '32041.pdf', nombre: 'El Salvador', color: '#FFA77F' },
        { id: 42, file: '32042.pdf', nombre: 'Sombrerete', color: '#70A800' },
        { id: 43, file: '32043.pdf', nombre: 'Susticacán', color: '#F7F2D2' },
        { id: 44, file: '32044.pdf', nombre: 'Tabasco', color: '#E6E600' },
        { id: 45, file: '32045.pdf', nombre: 'Tepechitlán', color: '#73B2FF' },
        { id: 46, file: '32046.pdf', nombre: 'Tepetongo', color: '#BFECF5' },
        { id: 47, file: '32047.pdf', nombre: 'Teúl de González Ortega', color: '#E6E600' },
        { id: 48, file: '32048.pdf', nombre: 'Tlaltenango de Sánchez Román', color: '#CCCCCC' },
        { id: 49, file: '32049.pdf', nombre: 'Valparaíso', color: '#E2C9F2' },
        { id: 50, file: '32050.pdf', nombre: 'Vetagrande', color: '#E6E600' },
        { id: 51, file: '32051.pdf', nombre: 'Villa de Cos', color: '#FFA77F' },
        { id: 52, file: '32052.pdf', nombre: 'Villa García', color: '#D6F7CD' },
        { id: 53, file: '32053.pdf', nombre: 'Villa González Ortega', color: '#D6F7CD' },
        { id: 54, file: '32054.pdf', nombre: 'Villa Hidalgo', color: '#F7F2D2' },
        { id: 55, file: '32055.pdf', nombre: 'Villanueva', color: '#E2C9F2' },
        { id: 56, file: '32056.pdf', nombre: 'Zacatecas', color: '#70A800' },
        { id: 57, file: '32057.pdf', nombre: 'Trancoso', color: '#F7F2D2' },
        { id: 58, file: '32058.pdf', nombre: 'Santa María de la Paz', color: '#70A800' }
    ];
    const [municipio, setMunicipio] = useState({});
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(false);

    const downFile = () => {
        setLoading(true);
        const file = `${route}${selected.file}`;
        clienteAxios.post(`https://admin-siem.zacatecas.gob.mx/api/pdf`, { file: file }, {
            responseType: 'blob',
        }).then(get => {
            const url = URL.createObjectURL(new Blob([get.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${selected.file}`
            );
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <h2>Cédula de Información Básica ZAP</h2>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-8'>
                    <div className='row'>
                        <div className="col-md-10" style={{ textAlign: 'center' }}>
                            {selected.id ? <h5><i>ZAP {selected.id}.-{selected.nombre}</i></h5> : <h5>&nbsp;</h5>}
                        </div>
                        {selected.id &&
                            <div className='col-md-2'>
                                <button type='button' className='btn btn-danger btn-sm' onClick={() => downFile()} disabled={loading}>
                                    Descargar <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='mapa'>
                        <MapContainer style={{ "width": "32vw", "height": "82vh" }} center={['22.818146', '-102.458302']} zoom={7} touchZoom={-1} zoomControl={true} scrollWheelZoom={false}>
                            <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                            <div className="leaflet-top leaflet-right">
                                <div className="info leaflet-control text-center" style={{ padding: '6px 8px', font: '14px/16px Arial, Helvetica, sans-serif', background: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.2)', borderRadius: '5px', opacity: 0.7 }}>
                                    <h6 style={{ color: '#777' }}>Zacatecas, México</h6>
                                    {municipio.id &&
                                        <div>
                                            <strong>{municipio.id} - {municipio.nombre}</strong><br></br>
                                            <label>ZAP</label>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="leaflet-bottom leaflet-left" style={{marginBottom: '1rem'}}>
                                <div className="leaflet-control"
                                    style={{ padding: '6px 8px', font: '14px/16px Arial, Helvetica, sans-serif', background: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.2)', borderRadius: '5px', opacity: 0.7 }}>
                                    <h6 style={{ color: '#777' }} className='text-center'>Simbología</h6>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div style={{ width: '17px', height: '13px', background: '#F7DC6F' }}></div>
                                        </div>
                                        <div className="col-md-9">
                                            Año 2019
                                        </div>
                                    </div>
                                    <div className="row" style={{marginTop: '0.4rem'}}>
                                        <div className="col-md-3">
                                            <div style={{ width: '17px', height: '13px', border: '2px solid #861923' }}></div>
                                        </div>
                                        <div className="col-md-9">
                                            Año 2022
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {dataGeoJson.features.map(dato => {
                                return (
                                    <GeoJSON
                                        key={dato.properties.CVE_MUN}
                                        data={dato}
                                        eventHandlers={{
                                            mouseover: (e) => {
                                                /* e.layer.setStyle({
                                                    fillOpacity: 1
                                                }); */
                                                var contentPopUp = `<strong>ZAP: ${dato.properties.CVE_AGEB}</strong>`;
                                                e.propagatedFrom.bindTooltip(contentPopUp).openTooltip();
                                                setMunicipio({
                                                    id: parseInt(dato.properties.CVE_MUN),
                                                    clave: dato.properties.CVEGEO,
                                                    nombre: dato.properties.NOMGEO
                                                });
                                            },
                                            mouseout: (e) => {
                                                setMunicipio({});
                                                e.layer.setStyle({
                                                    fillOpacity: dato.properties.year == '2019' ? 0.6 : 0
                                                });
                                            },
                                            click: (e) => {
                                                setSelected(municipios.filter(r => r.id == parseInt(dato.properties.CVE_MUN))[0]);
                                            },
                                        }}
                                        color={dato.properties.year == '2022' ? '#861923' : null} // color del borde
                                        fillColor={dato.properties.year == '2019' ? '#F7DC6F' : null} // color de relleno
                                        fillOpacity={dato.properties.year == '2019' ? 0.6 : 0}
                                        dashArray={'3'}>
                                    </GeoJSON>
                                );
                            })}
                        </MapContainer>
                    </div>
                </div>
                <div className='col-md-8' style={{ textAlign: 'center' }}>
                    {selected.id ?
                        <iframe title={selected.file} src={`https://admin-siem.zacatecas.gob.mx/storage/${route}${selected.file}#toolbar=0`} width="100%" height="100%" frameBorder="1"></iframe> :
                        <div width="100%" height="100%" style={{ marginRight: '1rem', marginTop: '8rem' }}>
                        </div>
                    }
                </div>
            </div>
            <br />
            <br />
        </React.Fragment>
    );
}

export default FichasZap;