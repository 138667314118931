import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
//import { Link } from 'react-router-dom';
import clienteAxios from '../../config/clientAxios';

const Carrusel = () => {

    const [images, setImages] = useState([]);
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        const getImages = async () => {
            await clienteAxios.get(`banner/images`, { timeout: 30000 })
                .then(response => {
                    setStatus(true);
                    console.log(response.data.images);
                    setImages(response.data.images);
                }).catch(error => {
                    setStatus(false);
                    console.error(error);
                });
        }
        getImages();
    }, []);

    /* const images = [
        { key: 1, image: 'https://coepla.zacatecas.gob.mx/wp-content/uploads/2023/09/DUBUJO2023-1024x574.png', alt: 'Convocatoria', url: 'https://coepla.zacatecas.gob.mx/wp-content/uploads/2023/09/Convocatoria-Concurso-de-Dibujo-2023.pdf' },
        { key: 2, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/07/BannersGob-copia2_extorsion-1920-x-773.png', alt: 'Llamadas de extorsión', url: null },
        { key: 3, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/07/BannersGob-lluvia_Banner-Gobierno-1920-x-773.png', alt: 'Lluvias', url: null },
        { key: 4, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/07/BannersGob-Pilares_Banner-Gobierno-1920-x-773-copia.png', alt: 'Gobierno', url: null },
        { key: 5, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/07/BannersGob-Pilares_Banner-Gobierno-1920-x-773.png', alt: 'Gobierno', url: null },
        { key: 6, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/06/Mayor-Conectividad-01.png', alt: 'Mayor conectividad', url: null },
        { key: 7, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/06/BannersGob_Banner-Gobierno-1920-x-773.png', alt: 'Gobierno', url: null },
        { key: 8, image: 'https://www.zacatecas.gob.mx/wp-content/uploads/2023/06/BannersGobsarai2_Banner-Gobierno-1920-x-773.png', alt: 'Gobierno', url: null },
    ]; */

    return (
        <React.Fragment>
            <div>
                <Carousel>
                    {images.length>0 && images.map((i) => {
                        return (
                            <Carousel.Item key={i.key}>
                                {
                                    <a target="_blank" rel="noreferrer"
                                        href={i.url}
                                        style={{ color: `#7D878E`, textDecoration: `none` }}>
                                        <img src={i.image} width={'50%'} height={'50%'} className="d-block w-100" alt={i.alt} />
                                    </a>
                                }
                                {/* <Link to={i.url} style={{ color: `#7D878E`, textDecoration: `none` }}>
                                    <img src={i.image} width={'50%'} height={'50%'} className="d-block w-100" alt={i.alt} />
                                </Link> */}
                                <Carousel.Caption>
                                </Carousel.Caption>
                            </Carousel.Item>
                        );
                    })}
                </Carousel>
            </div>
        </React.Fragment>
    );
}
export default Carrusel;