import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement,} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const Pobreza = () => {
    const [statusT, setStatusT] = useState(undefined);
    const [statusS, setStatusS] = useState(undefined);
    const [total, setTotal] = useState({ "datasets": [], "labels": [] });
    const [sexo, setSexo] = useState({ "datasets": [], "labels": [] });
    const optionsBar = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    const optionsLine = {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      };
    const [key, setKey] = useState('total');
    useEffect(() => {
        const getTotal = async () => {
            await clienteAxios.get(`cieg/demografia/poblacion/total`, { timeout: 30000 })
                .then(response => {
                    setStatusT(true);
                    setTotal(response.data.datasets);
                }).catch(error => {
                    setStatusT(false);
                    console.error(error);
                });
        }
        const getSexo = async () => {
            await clienteAxios.get(`cieg/demografia/poblacion/sexo`, { timeout: 30000 })
                .then(response => {
                    setStatusS(true);
                    setSexo(response.data.datasets);
                }).catch(error => {
                    setStatusS(false);
                    console.error(error);
                });
        }
        getTotal();
        getSexo();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="total" title="Total">
                    <div className='row justify-content-md-center'>
                        {statusT === true ? <Bar data={total} options={optionsBar} /> : <Cargando />}
                    </div>
                </Tab>
                <Tab eventKey="sexo" title="Por Sexo">
                    <div className='row justify-content-md-center'>
                    {statusS === true ? <Line options={optionsLine} data={sexo} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{'CONAPO'}</i></p>
                </div>
            </div>
        </div>
    );
}
export default Pobreza;