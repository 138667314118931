import React from 'react';
import { Link } from 'react-router-dom';

const Folder = ({ item }) => {
    return (
        <div className={`col-md-3 text-center`} style={{marginTop: '1rem'}}>
            <Link to={item.route} >
                <img src='/images/folder.png' width={'60%'} height={'70%'} alt={item.nombre} />
            </Link>
            <div className='row' style={{ marginTop: '0.5rem' }}>
                <span><strong> {item.nombre} </strong></span>
            </div>
        </div>
    );
}
export default Folder;