import React from 'react';
import { Col } from 'react-bootstrap';
import AnimatedNumbers from "react-animated-numbers";

const Habitantes = ({ cifra, title }) => {
    return (
        <Col>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}> 
                <h1>
                    <font color='#FFFFFF' align='center'>
                        <strong><AnimatedNumbers includeComma animationType={'calm'} animateToNumber={cifra} /></strong>
                    </font>
                </h1>
            </div>
            <div><p className="text-center"><font color='#FFFFFF'><b>{title}</b></font></p></div>
        </Col>
    );
}
export default Habitantes;