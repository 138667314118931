import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Pobreza = () => {

    const navigate = useNavigate();
    
    const images = [
        { id: 1, name: 'Pobreza por municipio 2020', image: 'publicaciones/documentos_analisis/pobreza/INFOGRAFIAPOBREZA2020.png', file: 'publicaciones/documentos_analisis/pobreza/INFOGRAFIAPOBREZA2020.pdf', type: 'PDF' },
        { id: 2, name: 'Medición de pobreza 2020', image: 'publicaciones/documentos_analisis/pobreza/Infografia_pobreza.png', file: 'publicaciones/documentos_analisis/pobreza/Infografia_pobreza.pdf', type: 'PDF' },
        { id: 3, name: 'Mapas Indicadores de Pobreza 2010, 2015 y 2020', image: 'publicaciones/documentos_analisis/pobreza/Mapas-Indicadores-de-Pobreza-2010-2020.png', file: 'publicaciones/documentos_analisis/pobreza/Mapas-Indicadores-de-Pobreza-2010-2020.pdf', type: 'PDF' },
        { id: 4, name: 'Resultados de Pobreza Municipal 2015', image: 'publicaciones/documentos_analisis/pobreza/Resultados-de-Pobreza-Municipal-2015.png', file: 'publicaciones/documentos_analisis/pobreza/Resultados-de-Pobreza-Municipal-2015.pdf', type: 'PDF' },
        { id: 5, name: 'Resultados de Marginación 2020 Zacatecas', image: 'publicaciones/documentos_analisis/pobreza/Resultados_marginacion2020.png', file: 'publicaciones/documentos_analisis/pobreza/Resultados_marginacion2020.pdf', type: 'PDF' },
        { id: 6, name: 'Índice de rezago social 2020', image: 'publicaciones/documentos_analisis/pobreza/Zacatecas-indice-de-rezago-social-2020.png', file: 'publicaciones/documentos_analisis/pobreza/Zacatecas-indice-de-rezago-social-2020.pdf', type: 'PDF' },
        { id: 7, name: 'Indicadores sociodemográficos Brechas por municipio 2020', image: 'publicaciones/documentos_analisis/pobreza/brechas2020-1.png', file: 'publicaciones/documentos_analisis/pobreza/brechas2020-1.pdf', type: 'PDF' },
        { id: 8, name: 'Medición de la Pobreza 2022', image: 'publicaciones/documentos_analisis/pobreza/pobrezaZac2022.png', file: 'publicaciones/documentos_analisis/pobreza/pobrezaZac2022.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Pobreza, rezago social y marginación</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Pobreza;