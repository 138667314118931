import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const Metales = () => {
    const [statusKg, setStatusKg] = useState(undefined);
    const [statusTon, setStatusTon] = useState(undefined);
    const [kg, setKg] = useState({ "datasets": [], "labels": [] });
    const [ton, setTon] = useState({ "datasets": [], "labels": [] });
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    const [key, setKey] = useState('kg');
    useEffect(() => {
        const getKg = async () => {
            await clienteAxios.get(`cieg/mineria/kg`, { timeout: 30000 })
                .then(response => {
                    setStatusKg(true);
                    setKg(response.data.datasets);
                }).catch(error => {
                    setStatusKg(false);
                    console.error(error);
                });
        }
        const getTon = async () => {
            await clienteAxios.get(`cieg/mineria/ton`, { timeout: 30000 })
                .then(response => {
                    setStatusTon(true);
                    setTon(response.data.datasets);
                }).catch(error => {
                    setStatusTon(false);
                    console.error(error);
                });
        }
        getKg();
        getTon();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="kg" title="Kilogramos">
                    <div className='row justify-content-md-center'>
                        {statusKg === true ? <Bar data={kg} options={options} /> : <Cargando />}
                    </div>
                </Tab>
                <Tab eventKey="ton" title="Toneladas">
                    <div className='row justify-content-md-center'>
                        {statusTon === true ? <Bar data={ton} options={options} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{"INEGI. Banco de Información Económica(BIE)."}</i></p>
                </div>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Nota: </strong><i>{"A partir de enero 2023 las cifras son preliminares"}</i></p>
                    <br/>
                    <p><i>{"Para el año 2023 las cifras son al mes de junio."}</i></p>
                </div>
            </div>
        </div>
    );
}
export default Metales;