import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Gobierno = () => {

    const navigate = useNavigate();

    const images = [
        { id: 1, name: 'Encuesta Nacional de Calidad e Impacto Gubernamental ENCIG 2021', image: 'publicaciones/documentos_analisis/gobierno/Encig2021_Zac.png', file: 'publicaciones/documentos_analisis/gobierno/Encig2021_Zac.pdf', type: 'PDF' },
        { id: 2, name: 'Encuesta Nacional de Calidad e Impacto Gubernamental ENCIG 2019', image: 'publicaciones/documentos_analisis/gobierno/Infografia-ENCIG-2019-2.png', file: 'publicaciones/documentos_analisis/gobierno/Infografia-ENCIG-2019-2.pdf', type: 'PDF' },
        { id: 3, name: 'Estadística Geoelectoral Zacatecas 2015', image: 'publicaciones/documentos_analisis/gobierno/Informacion-Geoelectoral.png', file: 'publicaciones/documentos_analisis/gobierno/Informacion-Geoelectoral.pdf', type: 'PDF' },
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Gobierno</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}

export default Gobierno;