import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Cargando from '../../../../extras/Cargando';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Barras = ({ route }) => {

    const [status, setStatus] = useState(undefined);
    const [datasets, setDatasets] = useState({ "datasets": [], "labels": [] });

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };

    useEffect(() => {
        const getData = async () => {
            await clienteAxios.get(`cieg/economia/${route}/total`, { timeout: 30000 })
                .then(response => {
                    setStatus(true);
                    setDatasets(response.data.datasets);
                }).catch(error => {
                    setStatus(false);
                    console.error(error);
                });
        }
        getData();
    }, []);

    return (
        <div>
            {status === true ? <Bar data={datasets} options={options} /> : <Cargando />}
        </div>
    );
}
export default Barras;