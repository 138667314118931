import React from 'react';
import { useState } from 'react';
import Graficas from './general/General';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Educacion = () => {

    const [show, setShow] = useState('promedio_escolaridad');

    const option = {
        promedio_escolaridad: 'Grado promedio de escolaridad.',
        analfabetismo: 'Analfabetismo.',
        alumnos: 'Alumnos',
        alumnos_nivel: 'Alumnos por nivel'
    };
    
    const info = {
        fuente: "Secretaría de Educación"
    };
    
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Educación</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'promedio_escolaridad' && 'active'} `} onClick={() => setShow('promedio_escolaridad')}>{option['promedio_escolaridad']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'analfabetismo' && 'active'} `} onClick={() => setShow('analfabetismo')}>{option['analfabetismo']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'alumnos_nivel' && 'active'} `} onClick={() => setShow('alumnos_nivel')}>{option['alumnos_nivel']}</button>
                </div>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'alumnos' && 'active'} `} onClick={() => setShow('alumnos')}>{option['alumnos']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'promedio_escolaridad' && <Graficas route={show} fuente={info.fuente} />}
                {show === 'analfabetismo' && <Graficas route={show} fuente={info.fuente} />}
                {show === 'alumnos' && <Graficas route={show} fuente={info.fuente} />}
                {show === 'alumnos_nivel' && <Graficas route={show} fuente={info.fuente} />}
            </div>
        </React.Fragment>
    );
}

export default Educacion;