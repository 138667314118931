import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import clienteAxios from '../../config/clientAxios';
import ReactTooltip from 'react-tooltip';
import { FileName } from '../extras/FuncionFiles';
import { PrimaryColor, SecondaryColor } from './FileColor';

const File = ({ doc, colSize = 2 }) => {
    const [loading, setLoading] = useState(false);
    const styleEnabled = { background: PrimaryColor(doc.type), color: '#FFFFFF', fontSize: '14', fontWeight: 'bold', cursor: 'pointer' };
    const styleDisabled = { background: SecondaryColor(doc.type), color: '#FFFFFF', fontSize: '14', fontWeight: 'bold', PointerEvent: 'none', cursor: 'not-allowed' };

    const downFile = (file) => {
        setLoading(true);
        clienteAxios.post(`https://admin-siem.zacatecas.gob.mx/api/pdf`, { file: file }, {
            responseType: 'blob',
        }).then(get => {
            const url = URL.createObjectURL(new Blob([get.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
                'download',
                `${FileName(file)}`
            )
            document.body.appendChild(link)
            link.click()
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className={`col-lg-${colSize} text-center`} style={{marginBottom: '2.5rem'}}>
                <div className="row">
                    <div className="col-md-12" style={{height: '2rem', lineHeight: '12px'}}>
                        {/* <font size={1}>1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890 1234567890</font> */}
                        <font size={1}>{doc.name}</font>
                    </div>
                </div>
                <Link to={doc.type === 'PDF' ? `/pdf/${doc.file}` : '#'} data-tip data-for={doc.file} style={doc.type !== 'PDF' ? styleDisabled : {}}>
                    <img src={`https://admin-siem.zacatecas.gob.mx/storage/${doc.image}`} height={'80%'} className="d-block w-100" alt={doc.name} style={{ border: '1px solid #000000', marginTop: '1rem' }} loading="lazy" />
                </Link>
                <ReactTooltip id={doc.file} effect='solid' place="right"><p>{doc.name}</p></ReactTooltip>
                <div className='row' style={{ marginTop: '0.5rem' }}>
                    <div className='col-lg-3'></div>
                    <div className='col-lg-6' style={loading ? styleDisabled : styleEnabled} onClick={() => !loading ? downFile(doc.file) : ''}>
                        <span><small>{doc.type}</small><FontAwesomeIcon icon={faDownload} size='2xs' style={{ marginLeft: '0.1rem' }}></FontAwesomeIcon></span>
                    </div>
                    <div className='col-lg-3'></div>
                </div>
            </div>
            <div className='col-lg-1 text-center'></div>
        </React.Fragment>
    );
}
export default File;