export const municipios = [
    { id: 1, clave: '32001', nombre: 'Apozol' },
    { id: 2, clave: '32002', nombre: 'Apulco' },
    { id: 3, clave: '32003', nombre: 'Atolinga' },
    { id: 4, clave: '32004', nombre: 'Benito Juárez' },
    { id: 5, clave: '32005', nombre: 'Calera' },
    { id: 6, clave: '32006', nombre: 'Cañitas de Felipe Pescador' },
    { id: 7, clave: '32007', nombre: 'Concepción del Oro' },
    { id: 8, clave: '32008', nombre: 'Cuauhtémoc' },
    { id: 9, clave: '32009', nombre: 'Chalchihuites' },
    { id: 10, clave: '32010', nombre: 'Fresnillo' },
    { id: 11, clave: '32011', nombre: 'Trinidad García de la Cadena' },
    { id: 12, clave: '32012', nombre: 'Genaro Codina' },
    { id: 13, clave: '32013', nombre: 'General Enrique Estrada' },
    { id: 14, clave: '32014', nombre: 'General Francisco R. Murguía' },
    { id: 15, clave: '32015', nombre: 'El Plateado de Joaquín Amaro' },
    { id: 16, clave: '32016', nombre: 'General Pánfilo Natera' },
    { id: 17, clave: '32017', nombre: 'Guadalupe' },
    { id: 18, clave: '32018', nombre: 'Huanusco' },
    { id: 19, clave: '32019', nombre: 'Jalpa' },
    { id: 20, clave: '32020', nombre: 'Jerez' },
    { id: 21, clave: '32021', nombre: 'Jiménez del Teul' },
    { id: 22, clave: '32022', nombre: 'Juan Aldama' },
    { id: 23, clave: '32023', nombre: 'Juchipila' },
    { id: 24, clave: '32024', nombre: 'Loreto' },
    { id: 25, clave: '32025', nombre: 'Luis Moya' },
    { id: 26, clave: '32026', nombre: 'Mazapil' },
    { id: 27, clave: '32027', nombre: 'Melchor Ocampo' },
    { id: 28, clave: '32028', nombre: 'Mezquital del Oro' },
    { id: 29, clave: '32029', nombre: 'Miguel Auza' },
    { id: 30, clave: '32030', nombre: 'Momax' },
    { id: 31, clave: '32031', nombre: 'Monte Escobedo' },
    { id: 32, clave: '32032', nombre: 'Morelos' },
    { id: 33, clave: '32033', nombre: 'Moyahua de Estrada' },
    { id: 34, clave: '32034', nombre: 'Nochistlán de Mejía' },
    { id: 35, clave: '32035', nombre: 'Noria de Ángeles' },
    { id: 36, clave: '32036', nombre: 'Ojocaliente' },
    { id: 37, clave: '32037', nombre: 'Pánuco' },
    { id: 38, clave: '32038', nombre: 'Pinos' },
    { id: 39, clave: '32039', nombre: 'Río Grande' },
    { id: 40, clave: '32040', nombre: 'Sain Alto' },
    { id: 41, clave: '32041', nombre: 'El Salvador' },
    { id: 42, clave: '32042', nombre: 'Sombrerete' },
    { id: 43, clave: '32043', nombre: 'Susticacán' },
    { id: 44, clave: '32044', nombre: 'Tabasco' },
    { id: 45, clave: '32045', nombre: 'Tepechitlán' },
    { id: 46, clave: '32046', nombre: 'Tepetongo' },
    { id: 47, clave: '32047', nombre: 'Teúl de González Ortega' },
    { id: 48, clave: '32048', nombre: 'Tlaltenango de Sánchez Román' },
    { id: 49, clave: '32049', nombre: 'Valparaíso' },
    { id: 50, clave: '32050', nombre: 'Vetagrande' },
    { id: 51, clave: '32051', nombre: 'Villa de Cos' },
    { id: 52, clave: '32052', nombre: 'Villa García' },
    { id: 53, clave: '32053', nombre: 'Villa González Ortega' },
    { id: 54, clave: '32054', nombre: 'Villa Hidalgo' },
    { id: 55, clave: '32055', nombre: 'Villanueva' },
    { id: 56, clave: '32056', nombre: 'Zacatecas' },
    { id: 57, clave: '32057', nombre: 'Trancoso' },
    { id: 58, clave: '32058', nombre: 'Santa María de la Paz' }
];