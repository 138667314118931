import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Cargando from '../../../../extras/Cargando';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Linea = ({route}) => {

  const [status, setStatus] = useState(undefined);
  const [datasets, setDatasets] = useState({ "datasets": [], "labels": [] });

  useEffect(() => {
    const getData = async () => {
      await clienteAxios.get(`cieg/ocupacion_empleo/${route}/sex`, { timeout: 30000 })
        .then(response => {
          setStatus(true);
          setDatasets(response.data.datasets);
        }).catch(error => {
          setStatus(false);
          console.error(error);
        });
    }
    getData();
  }, []);

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <div >
      {status === true ? <Line options={options} data={datasets} /> : <Cargando />}
    </div>
  );
}
export default Linea;