import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Agricultura from './Agricultura';
import SuperficieSembrada from './SuperficieSembrada';
import SuperficieCosechada from './SuperficieCosechada';
import ProduccionLeche from './ProduccionLeche';
import ProduccionCarne from './ProduccionCarne';

const AgriculturaGanaderia = () => {
    const [show, setShow] = useState('agri');
    const option = {
        agri: 'Agricultura',
        pca: 'Producción de Carne',
        ple: 'Producción de Leche',
        suco: 'Superficie Cosechada',
        suse: 'Superficie Sembrada'
    };
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Agricultura y Ganadería</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'agri' && 'active'} `} onClick={() => setShow('agri')}>{option['agri']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'suse' && 'active'} `} onClick={() => setShow('suse')}>{option['suse']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'suco' && 'active'} `} onClick={() => setShow('suco')}>{option['suco']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pca' && 'active'} `} onClick={() => setShow('pca')}>{option['pca']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'ple' && 'active'} `} onClick={() => setShow('ple')}>{option['ple']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
            {show === 'agri' && <Agricultura />}
            {show === 'suse' && <SuperficieSembrada />}
            {show === 'suco' && <SuperficieCosechada />}
            {show === 'ple' && <ProduccionLeche />}
            {show === 'pca' && <ProduccionCarne />}
            </div>
        </React.Fragment>
    );
}
export default AgriculturaGanaderia;