import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const Agricultura = () => {
    const [statusSuperficie, setStatusSuperficie] = useState(undefined);
    const [statusValor, setStatusValor] = useState(undefined);
    const [superficie, setSuperficie] = useState({ "datasets": [], "labels": [] });
    const [valor, setValor] = useState({ "datasets": [], "labels": [] });
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    const [key, setKey] = useState('superficie');
    useEffect(() => {
        const getSuperficie = async () => {
            await clienteAxios.get(`cieg/agricultura_ganaderia/agricultura/superficie`, { timeout: 30000 })
                .then(response => {
                    setStatusSuperficie(true);
                    setSuperficie(response.data.datasets);
                }).catch(error => {
                    setStatusSuperficie(false);
                    console.error(error);
                });
        }
        const getValor = async () => {
            await clienteAxios.get(`cieg/agricultura_ganaderia/agricultura/valor`, { timeout: 30000 })
                .then(response => {
                    setStatusValor(true);
                    setValor(response.data.datasets);
                }).catch(error => {
                    setStatusValor(false);
                    console.error(error);
                });
        }
        getSuperficie();
        getValor();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="superficie" title="Superficie">
                    <div className='row justify-content-md-center'>
                        {statusSuperficie === true ? <Bar data={superficie} options={options} /> : <Cargando />}
                    </div>
                </Tab>
                <Tab eventKey="valor" title="Valor">
                    <div className='row justify-content-md-center'>
                        {statusValor === true ? <Bar data={valor} options={options} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{"Servicio de Información Agroalimentaria y Pesquera"}</i></p>
                </div>
            </div>
        </div>
    );
}
export default Agricultura;