import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '../../assets/css/Zoom.css';

const SitiosInteres = () => {
    return (
        <div style={{ background: `white`, marginBothrefm: `1.45rem`, }}>
            <Container align='center' >
                <h1 className='center'><font color='#7D878E'>Sitios de interés</font></h1>
                <br />
                <Row>
                    <Col xs={4} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://coepla.zacatecas.gob.mx/" style={{ color: `#7D878E`, textDecoration: `none` }} >
                            <h4 className='text zoom'>COEPLA</h4>
                        </a>
                    </Col>
                    <Col xs={4} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://www.inegi.org.mx/" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>INEGI</h4>
                        </a>
                    </Col>
                    <Col xs={4} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://www.gob.mx/sesnsp" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>SNSP</h4>
                        </a>
                    </Col>
                    <Col xs={4} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://indyce.zacatecas.gob.mx/#!/" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>INDYCE</h4>
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://www.coneval.org.mx/Paginas/principal.aspx" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>CONEVAL</h4>
                        </a>
                    </Col>
                    <Col xs={6} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://www.snieg.mx/" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>SNIEG</h4>
                        </a>
                    </Col>
                    <Col xs={6} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://www.gob.mx/conapo" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>CONAPO</h4>
                        </a>
                    </Col>
                    <Col xs={6} md={3}>
                        <a target="_blank" rel="noreferrer" href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/" style={{ color: `#7D878E`, textDecoration: `none` }}>
                            <h4 className='text zoom'>ODS</h4>
                        </a>
                    </Col>
                </Row>
            </Container>
            <br />
        </div>
    );
}
export default SitiosInteres;