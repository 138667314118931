import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import clienteAxios from '../../config/clientAxios';
import { FileName } from '../extras/FuncionFiles';

const PdfSingle = ({ file, title = '' }) => {

  const [loading, setLoading] = useState(false);

  const downFile = (file) => {
    setLoading(true);
    clienteAxios.post(`https://admin-siem.zacatecas.gob.mx/api/pdf`, { file: file }, {
      responseType: 'blob',
    }).then(get => {
      const url = URL.createObjectURL(new Blob([get.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${FileName(file)}`
      )
      document.body.appendChild(link)
      link.click()
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <div className='container'>
      <div className='row' style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
        <div className='col-lg-2' style={{ textAlign: 'left' }}></div>
        <div className='col-lg-8 text-center'>
          <h2>{title}</h2>
        </div>
        <div className='col-lg-2' style={{ textAlign: 'right' }}>
          <button type='button' className='btn btn-outline-danger btn-block' onClick={() => downFile(file)} disabled={loading}>
            Descargar <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
      </div>
      <iframe title={"PDF"} src={`https://admin-siem.zacatecas.gob.mx/storage/${file}#toolbar=0`} width="100%" height="1000px" frameBorder="1"></iframe>
    </div>
  );
}
export default PdfSingle;