import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/Zoom.css';

const SectorLink = ({route, src, name}) => {
    return (
        <Link to={route} style={{ color: `#FFFFFF`, textDecoration: `none` }}>
            <img src={`/images/zacatecas-en-cifras/${src}`} width='150' height='150' className='zoom-sector' alt={name} />
            <div><p className="text-center"><h4><font color='#FFFFFF'>{name}</font></h4></p></div>
        </Link>
    );
}
export default SectorLink;