import React from 'react';

const Presentacion = () => {
    return (
        <div className='container'>
            <br/>
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Presentación</h2>
                </div>
            </div>
            <hr />
            <br />
            <div className='row'>
                <div className='col-lg-12'>
                    <p>
                        El Centro de Información Estadística y Geográfica del Estado de Zacatecas tiene como objetivo proveer información estratégica al gobierno y a la
                        sociedad para la toma de decisiones, mediante la recopilación y difusión de información estadística y geográfica generada por las diferentes instancias
                        gubernamentales y organismos públicos y privados.
                    </p>
                    <b>Esta plataforma cuenta con 4 herramientas principales:</b>
                    <br /><br />
                    <small>
                        <i>
                            1.- <b>Sistema de Información estadística municipal</b> que agrupa información de cuatro subsistemas: Demográfica y social, Económico, Geográfico y del Medio Ambiente por último Gobierno y Seguridad Pública. <br />
                            <hr style={{background: '#730E10'}} size={'4'}/>
                            2.- <b>Servicio de Información Georreferenciada</b>, en el cual podrás consultar capas de información básica como el marco geoestadístico del Estado, marginación, ageb´s prioritarias, pobreza, entre otras.<br />
                            <hr style={{background: '#730E10'}} size={'4'}/>
                            3.- <b>Proyectos del CEIEG</b> en el cual nos muestra una ficha técnica de los proyectos acreditados en el Comité Estatal de Información Estadística y Geográfica del Estado de Zacatecas.<br />
                            <hr style={{background: '#730E10'}} size={'4'}/>
                            4.- <b>Zacatecas en cifras</b>, en este apartado podemos consultar los principales datos demográficos y socioeconómicos del estado.
                        </i>
                    </small>
                </div>
            </div>
            <br /><br />
        </div>
    );
}
export default Presentacion;