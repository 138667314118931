import React from 'react';
import { useState } from 'react';
import Graficas from './general/General';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const OcupacionEmpleo = () => {

    const [show, setShow] = useState('pea');

    const option = {
        pea: 'Población económicamente activa',
        poe: 'Población ocupada no especificada',
        posp: 'Población ocupada sector primario',
        posse: 'Población ocupada sector secundario',
        post: 'Población ocupada sector terciario',
        po: 'Población ocupada',
        td: 'Tasa de desocupación',
        ti: 'Tasa de informalidad'
    };

    const info = {
        nota: "Para el II trimestre del 2020 no se dispone de información debido a la contingencia sanitaria, ya que el levantamiento de información fue suspendido.",
        fuente: " INEGI. Encuesta Nacional de Ocupación y Empleo (ENOE);  a partir del tercer trimestre de 2020, la información procede de la Encuesta Nacional de Ocupación y Empleo, Nueva edición (ENOEN)."
    };
    
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Ocupación y Empleo</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pea' && 'active'} `} onClick={() => setShow('pea')}>{option['pea']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'poe' && 'active'} `} onClick={() => setShow('poe')}>{option['poe']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'posp' && 'active'} `} onClick={() => setShow('posp')}>{option['posp']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'posse' && 'active'} `} onClick={() => setShow('posse')}>{option['posse']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'post' && 'active'} `} onClick={() => setShow('post')}>{option['post']}</button>
                </div>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'po' && 'active'} `} onClick={() => setShow('po')}>{option['po']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'td' && 'active'} `} onClick={() => setShow('td')}>{option['td']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'ti' && 'active'} `} onClick={() => setShow('ti')}>{option['ti']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'pea' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'poe' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'posp' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'posse' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'post' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'po' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'td' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'ti' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
            </div>
        </React.Fragment>
    );
}

export default OcupacionEmpleo;