import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Seguridad = () => {

    const navigate = useNavigate();
    
    const images = [
        //{ id: 1, name: 'Encuesta Nacional de Seguridad Urbana (ENSU) 1er Trimestre Marzo 2021', image: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS-1ER-TRIM-2021.png', file: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS-1ER-TRIM-2021.pdf', type: 'PDF' },
        //{ id: 2, name: 'Encuesta Nacional de Seguridad Urbana (ENSU) Diciembre 2021', image: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_Dic_2021.png', file: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_Dic_2021.pdf', type: 'PDF' },
        //{ id: 3, name: 'Encuesta Nacional de Seguridad Urbana (ENSU) Junio 2022', image: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_Junio_2022.png', file: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_Junio_2022.pdf', type: 'PDF' },
        //{ id: 4, name: 'Encuesta Nacional de Seguridad Urbana (ENSU) Marzo 2022', image: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_Marzo_2022.png', file: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_Marzo_2022.pdf', type: 'PDF' },
        //{ id: 5, name: 'Encuesta Nacional de Seguridad Urbana (ENSU) Junio 2021', image: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_jun_2021.png', file: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_jun_2021.pdf', type: 'PDF' },
        //{ id: 6, name: 'Encuesta Nacional de Seguridad Urbana (ENSU) Septiembre 2021', image: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_sept_2021.png', file: 'publicaciones/documentos_analisis/seguridad-publica/ENSU_ZACATECAS_sept_2021.pdf', type: 'PDF' },
        { id: 7, name: 'Delitos de alto impacto 2016-2021', image: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA-DELITOS-2022.png', file: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA-DELITOS-2022.pdf', type: 'PDF' },
        { id: 8, name: 'Delitos de alto impacto 2016-2020', image: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA-DELITOS-DE-ALTO-IMPACTO-2021.png', file: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA-DELITOS-DE-ALTO-IMPACTO-2021.pdf', type: 'PDF' },
        { id: 9, name: 'Zacatecas ENVE 2018', image: 'publicaciones/documentos_analisis/seguridad-publica/Victimazacion-de-Empresas-Infografia.png', file: 'publicaciones/documentos_analisis/seguridad-publica/Victimazacion-de-Empresas-Infografia.pdf', type: 'PDF' },
        { id: 10, name: 'Delitos de Alto Impacto 2015 - 2022', image: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA_DELITOS_2023.png', file: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA_DELITOS_2023.pdf', type: 'PDF' },
        { id: 11, name: 'Infografía delitos 2024', image: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA-DELITOS-2024.png', file: 'publicaciones/documentos_analisis/seguridad-publica/INFOGRAFIA-DELITOS-2024.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Seguridad Pública</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Seguridad;