import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer>
            <div className="row text-white" style={{ background: '#525251' }}>
                <div className="col-lg-3 text-center align-self-center mb-3 mt-3">
                    <a href="https://www.zacatecas.gob.mx/" target="_blank" rel="noopener noreferrer">
                        <img src={'https://www.zacatecas.gob.mx/wp-content/uploads/2021/11/vertical-white-300x286.png'} width='60%' alt='Gobierno del Estado de Zacatecas'/>
                    </a>
                </div>
                <div className="col-lg-3 mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-12 h4">Contacto</div>
                        <div className="col-lg-12">Cordinación Estatal de Planeación</div>
                        <div className="col-lg-12">Circuito Cerro del Gato s/n</div>
                        <div className="col-lg-12">Colonia Ciudad Gobierno</div>
                        <div className="col-lg-12">Edificio H | Segundo piso</div>
                        <div className="col-lg-12">C.P. 98160</div>
                        <div className="col-lg-12">Zacatecas, Zac.</div>
                    </div>
                </div>
                <div className="col-lg-3 mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-12 h4">Teléfonos COEPLA</div>
                        <br></br><br></br>
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faPhone} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;Tel. (01-492) 4915000,
                        </div>
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faPhone} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;Directo: (01-492) 4915052
                            <br></br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ext. 10400 Fax.10401
                        </div>
                        <div className='col-lg-12'>&nbsp;</div>
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faEnvelope} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;coepla@zacatecas.gob.mx
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-12 h4">Teléfonos</div>
                        <div className="col-lg-8">
                            <a href="tel://4929223005">
                                <span className="badge badge-pill badge-secondary">922 3005</span>
                            </a>
                            &nbsp;<small>Cruz Roja Mexicana</small>
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4929225350">
                                <span className="badge badge-pill badge-secondary">922 5350</span>
                            </a>
                            &nbsp;<small>Bomberos</small>
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4029220180">
                                <span className="badge badge-pill badge-secondary">922 0180</span>
                            </a>
                            &nbsp;<small>Policía Estatal Prev.</small>
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4929245320">
                                <span className="badge badge-pill badge-secondary">924 5320</span>
                            </a>
                            &nbsp;<small>Tránsito del Estado</small>
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4924915000">
                                <span className="badge badge-pill badge-secondary">491 5000</span>
                            </a>
                            &nbsp;<small>Cd. Administrativa</small>
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://911" >
                                <span className="badge badge-pill badge-secondary">911</span>
                            </a>
                            &nbsp;<small>Emergencias</small>
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://089">
                                <span className="badge badge-pill badge-secondary">089</span>
                            </a>
                            &nbsp;<small>Denuncia Anónima</small>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;