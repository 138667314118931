import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import IndicePaz from './IndicePaz';
import TramitesInstalacionesGobierno from './TramitesInstalacionesGobierno';
import TramitesKiosco from './TramitesKiosco';
import PercepcionInseguridad from './PercepcionInseguridad';
import Delitos from './Delitos';

const SeguridadGobierno = () => {
    const [show, setShow] = useState('idp');
    const option = {
        idp: 'Índice de Paz',
        tig: 'Trámites realizados en instalaciones de gobierno',
        tk: 'Trámites realizados en cajero automático o kiosco inteligente',
        pis: 'Percepcion de Inseguridad',
        del: 'Delitos del fuero común'
    };
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Seguridad y Gobierno</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'tig' && 'active'} `} onClick={() => setShow('tig')}>{option['tig']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'idp' && 'active'} `} onClick={() => setShow('idp')}>{option['idp']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'tk' && 'active'} `} onClick={() => setShow('tk')}>{option['tk']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pis' && 'active'} `} onClick={() => setShow('pis')}>{option['pis']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'del' && 'active'} `} onClick={() => setShow('del')}>{option['del']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'idp' && <IndicePaz />}
                {show === 'tig' && <TramitesInstalacionesGobierno />}
                {show === 'tk' && <TramitesKiosco />}
                {show === 'pis' && <PercepcionInseguridad />}
                {show === 'del' && <Delitos />}
            </div>
        </React.Fragment>
    );
}
export default SeguridadGobierno;