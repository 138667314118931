import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const DocAnalisis = () => {

    const navigate = useNavigate();
    
    const images = [
        { id: 1, name: 'Zacatecas Ensanut 2018', image: 'publicaciones/documentos_analisis/salud/infografia_ENSANUT2018.png', file: 'publicaciones/documentos_analisis/salud/infografia_ENSANUT2018.pdf', type: 'PDF' },
        { id: 2, name: 'Situación de la Salud Sexual y Reproductiva. República Mexicana', image: 'publicaciones/documentos_analisis/salud/Cuadernillo_SSR_RM.png', file: 'publicaciones/documentos_analisis/salud/Cuadernillo_SSR_RM.pdf', type: 'PDF' },
        { id: 3, name: 'Cuaderno ¡Yo Decido! Mi futuro', image: 'publicaciones/documentos_analisis/salud/Yo_Decido_Mi_Futuro_2020.png', file: 'publicaciones/documentos_analisis/salud/Yo_Decido_Mi_Futuro_2020.pdf', type: 'PDF' },
        { id: 4, name: 'NACIMIENTOS EN ADOLESCENTES 2022', image: 'publicaciones/documentos_analisis/salud/NACIMIENTOS_EN_ADOLESCENTES_2022.png', file: 'publicaciones/documentos_analisis/salud/NACIMIENTOS_EN_ADOLESCENTES_2022.pdf', type: 'PDF' }
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Salud</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default DocAnalisis;