import React, { useState } from 'react';
import { dataGeoJson } from './../../data/geojson/Regiones';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import clienteAxios from '../../../config/clientAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const FichasRegionales = () => {
    const route = 'publicaciones/fichas_regionales/';
    const regiones = [
        {id: 1, nombre: '1.-Región Centro', file: '1-REGION-CENTRO.pdf', color: '#1ABC9C'},
        {id: 2, nombre: '2.-Región Centro-Sur', file: '2-REGION-CENTRO-SUR.pdf', color: '#9B59B6'},
        {id: 3, nombre: '3.-Región Suroeste', file: '3-REGION-SUROESTE.pdf', color: '#E7700C'},
        {id: 4, nombre: '4.-Región Oeste', file: '4-REGION-OESTE.pdf', color: '#795548'},
        {id: 5, nombre: '5.-Región Noroeste', file: '5-REGION-NOROESTE.pdf', color: '#3498DB'},
        {id: 6, nombre: '6.-Región Norte', file: '6-REGION-NORTE.pdf', color: '#E74C3C'},
        {id: 7, nombre: '7.-Región Sureste', file: '7-REGION-SURESTE.pdf', color: '#424242'},
        {id: 8, nombre: '8.-Región Sur', file: '8-REGION-SUR.pdf', color: '#FFC300'}
    ];
    const [region, setRegion] = useState({});
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(false);

    const downFile = () => {
        setLoading(true);
        const file = `${route}${selected.file}`;
        clienteAxios.post(`https://admin-siem.zacatecas.gob.mx/api/pdf`, { file: file }, {
            responseType: 'blob',
        }).then(get => {
            const url = URL.createObjectURL(new Blob([get.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${selected.file}`
            );
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-12' style={{ textAlign: 'center' }}>
                    <h2>Cédula de Información Básica Regional</h2>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-8'>
                    <div className='row'>
                        <div className="col-md-10" style={{ textAlign: 'center' }}>
                            {selected.id ? <h5><i>Región {selected.nombre}</i></h5> : <h5>&nbsp;</h5>}
                        </div>
                        {selected.id &&
                            <div className='col-md-2'>
                                <button type='button' className='btn btn-danger btn-sm' onClick={() => downFile()} disabled={loading}>
                                    Descargar <FontAwesomeIcon icon={faDownload} />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='mapa'>
                        <MapContainer style={{ "width": "32vw", "height": "82vh" }} center={['22.818146', '-102.458302']} zoom={7} minZoom={6} maxZoom={11} touchZoom={-1} zoomControl={true} scrollWheelZoom={false}>
                            <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                            <div className="leaflet-top leaflet-right">
                                <div className="info leaflet-control text-center" style={{ padding: '6px 8px', font: '14px/16px Arial, Helvetica, sans-serif', background: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.2)', borderRadius: '5px', opacity: 0.7 }}>
                                    <h6 style={{ color: '#777' }}>Zacatecas, México</h6>
                                    {region.id &&
                                        <div>
                                            <strong>{region.id} - {region.nombre}</strong><br></br>
                                            <label>Región</label>
                                        </div>
                                    }
                                </div>
                            </div>
                            {dataGeoJson.features.map(dato => {
                                return (
                                    <GeoJSON
                                        key={dato.properties.OBJECTID}
                                        data={dato}
                                        eventHandlers={{
                                            mouseover: (e) => {
                                                e.layer.setStyle({
                                                    fillOpacity: 1
                                                });
                                                let contentPopUp = `<strong>${dato.properties.OBJECTID}.-${dato.properties.NOM_REGF}</strong>`;
                                                e.propagatedFrom.bindTooltip(contentPopUp).openTooltip();
                                                setRegion({
                                                    id: parseInt(dato.properties.OBJECTID),
                                                    nombre: dato.properties.NOM_REGF
                                                });
                                            },
                                            mouseout: (e) => {
                                                setRegion({});
                                                e.layer.setStyle({
                                                    fillOpacity: 0.6
                                                });
                                            },
                                            click: (e) => {
                                                setSelected(regiones.filter(r => r.id == dato.properties.OBJECTID)[0]);
                                            },
                                        }}
                                        color={'white'}
                                        fillColor={regiones.filter(r => r.id == dato.properties.OBJECTID)[0].color}
                                        fillOpacity={0.6}
                                        dashArray={'3'}>
                                    </GeoJSON>
                                );
                            })}
                        </MapContainer>
                    </div>
                </div>
                <div className='col-md-8' style={{ textAlign: 'center' }}>
                    {selected.id ?
                        <iframe title={selected.file} src={`https://admin-siem.zacatecas.gob.mx/storage/${route}${selected.file}#toolbar=0`} width="100%" height="100%" frameBorder="1"></iframe> :
                        <div width="100%" height="100%" style={{ marginRight: '1rem', marginTop: '15rem' }}>
                            <h5>
                                <i style={{ color: 'gray' }}>
                                    Documentos compilatorios a nivel de Regiones Copladez con información Sociodemográfica, Económica, Educativa y de Salud proveniente del Censo de Población y Vivienda 2020. Presionar sobre el mapa en la región de su interés para mostrar la Ficha correspondiente.
                                </i>
                            </h5>
                        </div>
                    }
                </div>
            </div>
            <br />
            <br />
        </React.Fragment>
    );
}

export default FichasRegionales;