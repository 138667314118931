import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PercepcionInseguridad = () => {
    const [status, setStatus] = useState(undefined);
    const [datasets, setDatasets] = useState({ "datasets": [], "labels": [] });
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    const [key, setKey] = useState('total');
    useEffect(() => {
        const getData = async () => {
            await clienteAxios.get(`cieg/seguridad_gobierno/percepcion_inseguridad/total`, { timeout: 30000 })
                .then(response => {
                    setStatus(true);
                    setDatasets(response.data.datasets);
                }).catch(error => {
                    setStatus(false);
                    console.error(error);
                });
        }
        getData();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="total" title="Total">
                    <div className='row justify-content-md-center'>
                        {status === true ? <Bar data={datasets} options={options} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{"INEGI. Encuesta Nacional de Victimización y Percepción sobre Seguridad Pública (Envipe)"}</i></p>
                </div>
            </div>
        </div>
    );
}
export default PercepcionInseguridad;