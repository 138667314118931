import React from 'react';
import File from '../../../extras/File';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Carta = () => {

    const navigate = useNavigate();
  
    const images = [
        { id: 1, name: 'APOZOL', image: 'publicaciones/croquis_municipal/carta/001_APOZOL_2022.png', file: 'publicaciones/croquis_municipal/carta/001_APOZOL_2022.pdf', type: 'PDF' },
        { id: 2, name: 'APULCO', image: 'publicaciones/croquis_municipal/carta/002_APULCO_2022.png', file: 'publicaciones/croquis_municipal/carta/002_APULCO_2022.pdf', type: 'PDF' },
        { id: 3, name: 'ATOLINGA', image: 'publicaciones/croquis_municipal/carta/003_ATOLINGA_2022.png', file: 'publicaciones/croquis_municipal/carta/003_ATOLINGA_2022.pdf', type: 'PDF' },
        { id: 4, name: 'BENITO JUAREZ', image: 'publicaciones/croquis_municipal/carta/004_BENITO_JUAREZ_2022.png', file: 'publicaciones/croquis_municipal/carta/004_BENITO_JUAREZ_2022.pdf', type: 'PDF' },
        { id: 5, name: 'CALERA', image: 'publicaciones/croquis_municipal/carta/005_CALERA_2022.png', file: 'publicaciones/croquis_municipal/carta/005_CALERA_2022.pdf', type: 'PDF' },
        { id: 6, name: 'CAÑITAS', image: 'publicaciones/croquis_municipal/carta/006_CANITAS_2022.png', file: 'publicaciones/croquis_municipal/carta/006_CANNITAS_2022.pdf', type: 'PDF' },
        { id: 7, name: 'CONCEPCION', image: 'publicaciones/croquis_municipal/carta/007_CONCEPCION_2022.png', file: 'publicaciones/croquis_municipal/carta/007_CONCEPCION_2022.pdf', type: 'PDF' },
        { id: 8, name: 'CUAUHTEMOC_20221', image: 'publicaciones/croquis_municipal/carta/008_CUAUHTEMOC_20221.png', file: 'publicaciones/croquis_municipal/carta/008_CUAUHTEMOC_20221.pdf', type: 'PDF' },
        { id: 9, name: 'CHALCHIHUITES', image: 'publicaciones/croquis_municipal/carta/009_CHALCHIHUITES_2022.png', file: 'publicaciones/croquis_municipal/carta/009_CHALCHIHUITES_2022.pdf', type: 'PDF' },
        { id: 10, name: 'FRESNILLO', image: 'publicaciones/croquis_municipal/carta/010_FRESNILLO_2022.png', file: 'publicaciones/croquis_municipal/carta/010_FRESNILLO_2022.pdf', type: 'PDF' },
        { id: 11, name: 'TRINIDAD GARCIA DE LA CADENA', image: 'publicaciones/croquis_municipal/carta/011_TRINIDAD_GARCIA_DE_LA_CADENA_2022.png', file: 'publicaciones/croquis_municipal/carta/011_TRINIDAD_GARCIA_DE_LA_CADENA_2022.pdf', type: 'PDF' },
        { id: 12, name: 'GENARO CODINA', image: 'publicaciones/croquis_municipal/carta/012_GENARO_CODINA_2022.png', file: 'publicaciones/croquis_municipal/carta/012_GENARO_CODINA_2022.pdf', type: 'PDF' },
        { id: 13, name: 'GRAL ENRIQUE ESTRADA', image: 'publicaciones/croquis_municipal/carta/013_GRAL_ENRIQUE_ESTRADA_2022.png', file: 'publicaciones/croquis_municipal/carta/013_GRAL_ENRIQUE_ESTRADA_2022.pdf', type: 'PDF' },
        { id: 14, name: 'GRAL FCO R MURGUIA', image: 'publicaciones/croquis_municipal/carta/014_GRAL_FCO_R_MURGUIA_2022.png', file: 'publicaciones/croquis_municipal/carta/014_GRAL_FCO_R_MURGUIA_2022.pdf', type: 'PDF' },
        { id: 15, name: 'EL PLATEADO', image: 'publicaciones/croquis_municipal/carta/015_EL_PLATEADO_2022.png', file: 'publicaciones/croquis_municipal/carta/015_EL_PLATEADO_2022.pdf', type: 'PDF' },
        { id: 16, name: 'GRAL PANFILO_NATERA', image: 'publicaciones/croquis_municipal/carta/016_GRAL_PANFILO_NATERA_2022.png', file: 'publicaciones/croquis_municipal/carta/016_GRAL_PANFILO_NATERA_2022.pdf', type: 'PDF' },
        { id: 17, name: 'GUADALUPE', image: 'publicaciones/croquis_municipal/carta/017_GUADALUPE_2022.png', file: 'publicaciones/croquis_municipal/carta/017_GUADALUPE_2022.pdf', type: 'PDF' },
        { id: 18, name: 'HUANUSCO', image: 'publicaciones/croquis_municipal/carta/018_HUANUSCO_2022.png', file: 'publicaciones/croquis_municipal/carta/018_HUANUSCO_2022.pdf', type: 'PDF' },
        { id: 19, name: 'JALPA', image: 'publicaciones/croquis_municipal/carta/019_JALPA_2022.png', file: 'publicaciones/croquis_municipal/carta/019_JALPA_2022.pdf', type: 'PDF' },
        { id: 20, name: 'JEREZ', image: 'publicaciones/croquis_municipal/carta/020_JEREZ_2022.png', file: 'publicaciones/croquis_municipal/carta/020_JEREZ_2022.pdf', type: 'PDF' },
        { id: 21, name: 'JIMENEZ DEL TEUL', image: 'publicaciones/croquis_municipal/carta/021_JIMENEZ_DEL_TEUL_2022.png', file: 'publicaciones/croquis_municipal/carta/021_JIMENEZ_DEL_TEUL_2022.pdf', type: 'PDF' },
        { id: 22, name: 'JUAN_ALDAMA', image: 'publicaciones/croquis_municipal/carta/022_JUAN_ALDAMA_2022.png', file: 'publicaciones/croquis_municipal/carta/022_JUAN_ALDAMA_2022.pdf', type: 'PDF' },
        { id: 23, name: 'JUCHIPILA', image: 'publicaciones/croquis_municipal/carta/023_JUCHIPILA_2022.png', file: 'publicaciones/croquis_municipal/carta/023_JUCHIPILA_2022.pdf', type: 'PDF' },
        { id: 24, name: 'LORETO', image: 'publicaciones/croquis_municipal/carta/024_LORETO_2022.png', file: 'publicaciones/croquis_municipal/carta/024_LORETO_2022.pdf', type: 'PDF' },
        { id: 25, name: 'LUIS_MOYA', image: 'publicaciones/croquis_municipal/carta/025_LUIS_MOYA_2022.png', file: 'publicaciones/croquis_municipal/carta/025_LUIS_MOYA_2022.pdf', type: 'PDF' },
        { id: 26, name: 'MAZAPIL', image: 'publicaciones/croquis_municipal/carta/026_MAZAPIL_2022.png', file: 'publicaciones/croquis_municipal/carta/026_MAZAPIL_2022.pdf', type: 'PDF' },
        { id: 27, name: 'MELCHOR OCAMPO', image: 'publicaciones/croquis_municipal/carta/027_MELCHOR_OCAMPO_2022.png', file: 'publicaciones/croquis_municipal/carta/027_MELCHOR_OCAMPO_2022.pdf', type: 'PDF' },
        { id: 28, name: 'MEZQUITAL DEL ORO', image: 'publicaciones/croquis_municipal/carta/028_MEZQUITAL_DEL_ORO_2022.png', file: 'publicaciones/croquis_municipal/carta/028_MEZQUITAL_DEL_ORO_2022.pdf', type: 'PDF' },
        { id: 29, name: 'MIGUEL AUZA', image: 'publicaciones/croquis_municipal/carta/029_MIGUEL_AUZA_2022.png', file: 'publicaciones/croquis_municipal/carta/029_MIGUEL_AUZA_2022.pdf', type: 'PDF' },
        { id: 30, name: 'MOMAX', image: 'publicaciones/croquis_municipal/carta/030_MOMAX_2022.png', file: 'publicaciones/croquis_municipal/carta/030_MOMAX_2022.pdf', type: 'PDF' },
        { id: 31, name: 'MONTE ESCOBEDO', image: 'publicaciones/croquis_municipal/carta/031_MONTE_ESCOBEDO_2022.png', file: 'publicaciones/croquis_municipal/carta/031_MONTE_ESCOBEDO_2022.pdf', type: 'PDF' },
        { id: 32, name: 'MORELOS', image: 'publicaciones/croquis_municipal/carta/032_MORELOS_2022.png', file: 'publicaciones/croquis_municipal/carta/032_MORELOS_2022.pdf', type: 'PDF' },
        { id: 33, name: 'MOYAHUA DE ESTRADA', image: 'publicaciones/croquis_municipal/carta/033_MOYAHUA_DE_ESTRADA_2022.png', file: 'publicaciones/croquis_municipal/carta/033_MOYAHUA_DE_ESTRADA_2022.pdf', type: 'PDF' },
        { id: 34, name: 'NOCHISTLAN DE MEJIA', image: 'publicaciones/croquis_municipal/carta/034_NOCHISTLAN_DE MEJIA_2022.png', file: 'publicaciones/croquis_municipal/carta/034_NOCHISTLAN_DE MEJIA_2022.pdf', type: 'PDF' },
        { id: 35, name: 'NORIA_DE ANGELES', image: 'publicaciones/croquis_municipal/carta/035_NORIA_DE ANGELES_2022.png', file: 'publicaciones/croquis_municipal/carta/035_NORIA_DE ANGELES_2022.pdf', type: 'PDF' },
        { id: 36, name: 'OJOCALIENTE', image: 'publicaciones/croquis_municipal/carta/036_OJOCALIENTE_2022.png', file: 'publicaciones/croquis_municipal/carta/036_OJOCALIENTE_2022.pdf', type: 'PDF' },
        { id: 37, name: 'PANUCO', image: 'publicaciones/croquis_municipal/carta/037_PANUCO_2022.png', file: 'publicaciones/croquis_municipal/carta/037_PANUCO_2022.pdf', type: 'PDF' },
        { id: 38, name: 'PINOS', image: 'publicaciones/croquis_municipal/carta/038_PINOS_2022.png', file: 'publicaciones/croquis_municipal/carta/038_PINOS_2022.pdf', type: 'PDF' },
        { id: 39, name: 'RIO GRANDE', image: 'publicaciones/croquis_municipal/carta/039_RIO_GRANDE_2022.png', file: 'publicaciones/croquis_municipal/carta/039_RIO_GRANDE_2022.pdf', type: 'PDF' },
        { id: 40, name: 'SAIN ALTO', image: 'publicaciones/croquis_municipal/carta/040_SAIN_ALTO_2022.png', file: 'publicaciones/croquis_municipal/carta/040_SAIN_ALTO_2022.pdf', type: 'PDF' },
        { id: 41, name: 'EL SALVADOR', image: 'publicaciones/croquis_municipal/carta/041_EL_SALVADOR_2022.png', file: 'publicaciones/croquis_municipal/carta/041_EL_SALVADOR_2022.pdf', type: 'PDF' },
        { id: 42, name: 'SOMBRERETE', image: 'publicaciones/croquis_municipal/carta/042_SOMBRERETE_2022.png', file: 'publicaciones/croquis_municipal/carta/042_SOMBRERETE_2022.pdf', type: 'PDF' },
        { id: 43, name: 'SUSTICACAN', image: 'publicaciones/croquis_municipal/carta/043_SUSTICACAN_2022.png', file: 'publicaciones/croquis_municipal/carta/043_SUSTICACAN_2022.pdf', type: 'PDF' },
        { id: 44, name: 'TABASCO', image: 'publicaciones/croquis_municipal/carta/044_TABASCO_2022.png', file: 'publicaciones/croquis_municipal/carta/044_TABASCO_2022.pdf', type: 'PDF' },
        { id: 45, name: 'TEPECHITLAN', image: 'publicaciones/croquis_municipal/carta/045_TEPECHITLAN_2022.png', file: 'publicaciones/croquis_municipal/carta/045_TEPECHITLAN_2022.pdf', type: 'PDF' },
        { id: 46, name: 'TEPETONGO', image: 'publicaciones/croquis_municipal/carta/046_TEPETONGO_2022.png', file: 'publicaciones/croquis_municipal/carta/046_TEPETONGO_2022.pdf', type: 'PDF' },
        { id: 47, name: 'TEUL_DE GLZ ORTEGA', image: 'publicaciones/croquis_municipal/carta/047_TEUL_DE_GLZ_ORTEGA_2022.png', file: 'publicaciones/croquis_municipal/carta/047_TEUL_DE_GLZ_ORTEGA_2022.pdf', type: 'PDF' },
        { id: 48, name: 'TLALTENANGO', image: 'publicaciones/croquis_municipal/carta/048_TLALTENANGO_2022.png', file: 'publicaciones/croquis_municipal/carta/048_TLALTENANGO_2022.pdf', type: 'PDF' },
        { id: 49, name: 'VALPARAISO', image: 'publicaciones/croquis_municipal/carta/049_VALPARAISO_2022.png', file: 'publicaciones/croquis_municipal/carta/049_VALPARAISO_2022.pdf', type: 'PDF' },

        { id: 50, name: "VETAGRANDE", image: "publicaciones/croquis_municipal/carta/050_VETAGRANDE_2022.png", file: "publicaciones/croquis_municipal/carta/050_VETAGRANDE_2022.pdf", type: "PDF" },
        { id: 51, name: "VILLA DE COS", image: "publicaciones/croquis_municipal/carta/051_VILLA_DE_COS_2022.png", file: "publicaciones/croquis_municipal/carta/051_VILLA_DE_COS_2022.pdf.pdf", type: "PDF" },
        { id: 52, name: "VILLA GARCÍA", image: "publicaciones/croquis_municipal/carta/052_VILLA_GARCIA_2022.png", file: "publicaciones/croquis_municipal/carta/052_VILLA_GARCIA_2022.pdf", type: "PDF" },
        { id: 53, name: "VILLA GONZÁLEZ ORTEGA", image: "publicaciones/croquis_municipal/carta/053_VILLA_GLZ_ORTEGA_2022.png", file: "publicaciones/croquis_municipal/carta/053_VILLA_GLZ_ORTEGA_2022.pdf", type: "PDF" },
        { id: 54, name: "VILLA HIDALGO", image: "publicaciones/croquis_municipal/carta/054_VILLA_HIDALGO_2022.png", file: "publicaciones/croquis_municipal/carta/054_VILLA_HIDALGO_2022.pdf", type: "PDF" },
        { id: 55, name: "VILLANUEVA", image: "publicaciones/croquis_municipal/carta/055_VILLANUEVA_2022.png", file: "publicaciones/croquis_municipal/carta/055_VILLANUEVA_2022.pdf", type: "PDF" },
        { id: 56, name: "ZACATECAS", image: "publicaciones/croquis_municipal/carta/056_ZACATECAS_2022.png", file: "publicaciones/croquis_municipal/carta/056_ZACATECAS_2022.pdf", type: "PDF" },

        { id: 57, name: 'TRANCOSO', image: 'publicaciones/croquis_municipal/carta/057_TRANCOSO_2022.png', file: 'publicaciones/croquis_municipal/carta/057_TRANCOSO_2022.pdf', type: 'PDF' },
        { id: 58, name: 'SANTA MARIA DE LA PAZ', image: 'publicaciones/croquis_municipal/carta/058_SANTA_MARIA_DE_LA_PAZ_2022.png', file: 'publicaciones/croquis_municipal/carta/058_SANTA_MARIA_DE_LA_PAZ_2022.pdf', type: 'PDF' }
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Croquis Municipales - Tamaño Carta</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}

export default Carta;