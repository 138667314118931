import React from 'react';
import File from '../../extras/File';

const MapasTematicos = () => {
    
    const images = [
        { id: 1, name: 'Carta Castral Zacatecas.', image: 'publicaciones/mapas_tematicos/CARTA-CATASTRAL-ZACATECAS.png', file: 'publicaciones/mapas_tematicos/CARTA-CATASTRAL-ZACATECAS.pdf', type: 'PDF' },
        { id: 2, name: 'Población con Discapacidad Zacatecas.', image: 'publicaciones/mapas_tematicos/Poblacion-con-discapacidad.png', file: 'publicaciones/mapas_tematicos/Poblacion-con-discapacidad.pdf', type: 'PDF' },
        { id: 3, name: 'Población con discapacidad para caminar, subir o bajar.', image: 'publicaciones/mapas_tematicos/Poblacion-con-discapacidad_caminar.png', file: 'publicaciones/mapas_tematicos/Poblacion-con-discapacidad_caminar.pdf', type: 'PDF' },
        { id: 4, name: 'Población con Limitación Zacatecas.', image: 'publicaciones/mapas_tematicos/Poblacion-con-limitacion-en-la-actividad.png', file: 'publicaciones/mapas_tematicos/Poblacion-con-limitacion-en-la-actividad.pdf', type: 'PDF' },
        { id: 5, name: 'Energías Renovables.', image: 'publicaciones/mapas_tematicos/Energias-Renovables.png', file: 'publicaciones/mapas_tematicos/Energias-Renovables.pdf', type: 'PDF' },
        { id: 6, name: 'Rezago Social por Estado y Municipio 2020.', image: 'publicaciones/mapas_tematicos/Rezago-Social-por-Estado-y-por-Municipio-2020.png', file: 'publicaciones/mapas_tematicos/Rezago-Social-por-Estado-y-por-Municipio-2020.pdf', type: 'PDF' },
        { id: 7, name: 'IDH(Índice de Desarrollo Humano) Municipal 2015.', image: 'publicaciones/mapas_tematicos/IDH-ZAC-2015.png', file: 'publicaciones/mapas_tematicos/IDH-ZAC-2015.pdf', type: 'PDF' },
        { id: 8, name: 'ICFM(Índice de Capacidades Funcionales de los Municipios) Zacatecas 2016', image: 'publicaciones/mapas_tematicos/ICFM-ZAC-2016.png', file: 'publicaciones/mapas_tematicos/ICFM-ZAC-2016.pdf', type: 'PDF' },
        { id: 9, name: 'Marginación por Municipio y por Estado 2015.', image: 'publicaciones/mapas_tematicos/Marginacion-por-Municipio-y-por-Estado-2015.png', file: 'publicaciones/mapas_tematicos/Marginacion-por-Municipio-y-por-Estado-2015.pdf', type: 'PDF' },
        { id: 10, name: 'Marginación por Municipio y por Estado 2020.', image: 'publicaciones/mapas_tematicos/Marginacion-por-Municipio-y-por-Estado-2020.png', file: 'publicaciones/mapas_tematicos/Marginacion-por-Municipio-y-por-Estado-2020.pdf', type: 'PDF' },
        { id: 11, name: 'Regiones COPLADEZ 1', image: 'publicaciones/mapas_tematicos/REGIONES_COPLADEZ_1.png', file: 'publicaciones/mapas_tematicos/REGIONES_COPLADEZ_1.pdf', type: 'PDF' },
        { id: 12, name: 'Regiones COPLADEZ 2', image: 'publicaciones/mapas_tematicos/REGIONES_COPLADEZ_2.png', file: 'publicaciones/mapas_tematicos/REGIONES_COPLADEZ_2.pdf', type: 'PDF' },
        { id: 13, name: 'Zacatecas Envipe 2021', image: 'publicaciones/mapas_tematicos/infografia_envipe2021.png', file: 'publicaciones/mapas_tematicos/infografia_envipe2021.pdf', type: 'PDF' },
        { id: 14, name: 'Zacatecas Envipe 2022', image: 'publicaciones/mapas_tematicos/infografia_envipe2022.png', file: 'publicaciones/mapas_tematicos/infografia_envipe2022.pdf', type: 'PDF' },
        { id: 15, name: 'IDH 2020 (Índice de Desarrollo Humano Municipal) Zacatecas', image: 'publicaciones/mapas_tematicos/INFOGRAFIA_IDH_ZAC_2020.png', file: 'publicaciones/mapas_tematicos/INFOGRAFIA_IDH_ZAC_2020.pdf', type: 'PDF' },
        { id: 16, name: 'ICFM (Índice de Capacidades Funcionales Municipales) Zacatecas 2018', image: 'publicaciones/mapas_tematicos/INFOGRAFIA_ICFM_ZAC_2018.png', file: 'publicaciones/mapas_tematicos/INFOGRAFIA_ICFM_ZAC_2018.pdf', type: 'PDF' },
        { id: 17, name: 'INFOGRAFIA IDG ZAC 2020 (DIMENSIONES)', image: 'publicaciones/mapas_tematicos/INFOGRAFIA_IDG_ZAC_2020_(DIMENSIONES).png', file: 'publicaciones/mapas_tematicos/INFOGRAFIA_IDG_ZAC_2020_(DIMENSIONES).pdf', type: 'PDF' },
        { id: 18, name: 'INFOGRAFIA IDG ZAC 2020 (INDICADORES)', image: 'publicaciones/mapas_tematicos/INFOGRAFIA_IDG_ZAC_2020_(INDICADORES).png', file: 'publicaciones/mapas_tematicos/INFOGRAFIA_IDG_ZAC_2020_(INDICADORES).pdf', type: 'PDF' },
        { id: 19, name: 'Zacatecas Envipe 2023', image: 'publicaciones/mapas_tematicos/infografia_envipe2023.png', file: 'publicaciones/mapas_tematicos/infografia_envipe2023.pdf', type: 'PDF' }
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Mapas Temáticos</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default MapasTematicos;