import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const Hospedaje = () => {
    const [statusEstablecimientos, setStatusEstablecimientos] = useState(undefined);
    const [establecimientos, setEstablecimientos] = useState({ "datasets": [], "labels": [] });

    const [statusHabitaciones, setStatusHabitaciones] = useState(undefined);
    const [habitaciones, setHabitaciones] = useState({ "datasets": [], "labels": [] });

    const [statusOcupacion, setStatusOcupacion] = useState(undefined);
    const [ocupacion, setOcupacion] = useState({ "datasets": [], "labels": [] });
    
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    //hospedaje, establecimientos_hospedaje, habitaciones ,ocupacion_hotelera
    const [key, setKey] = useState('establecimientos');
    useEffect(() => {
        const getEstablecimientos = async () => {
            await clienteAxios.get(`cieg/turismo/hospedaje/establecimientos_hospedaje`, { timeout: 30000 })
                .then(response => {
                    setStatusEstablecimientos(true);
                    setEstablecimientos(response.data.datasets);
                }).catch(error => {
                    setStatusEstablecimientos(false);
                    console.error(error);
                });
        }
        const getHabitaciones = async () => {
            await clienteAxios.get(`cieg/turismo/hospedaje/habitaciones`, { timeout: 30000 })
                .then(response => {
                    setStatusHabitaciones(true);
                    setHabitaciones(response.data.datasets);
                }).catch(error => {
                    setStatusHabitaciones(false);
                    console.error(error);
                });
        }
        const getOcupacion = async () => {
            await clienteAxios.get(`cieg/turismo/hospedaje/ocupacion_hotelera`, { timeout: 30000 })
                .then(response => {
                    setStatusOcupacion(true);
                    setOcupacion(response.data.datasets);
                }).catch(error => {
                    statusOcupacion(false);
                    console.error(error);
                });
        }
        getEstablecimientos();
        getHabitaciones();
        getOcupacion();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="establecimientos" title="Establecimientos de hospedaje ">
                    <div className='row justify-content-md-center'>
                        {statusEstablecimientos === true ? <Bar data={establecimientos} options={options} /> : <Cargando />}
                    </div>
                </Tab>
                <Tab eventKey="habitaciones" title="Habitaciones">
                    <div className='row justify-content-md-center'>
                        {statusHabitaciones === true ? <Bar data={habitaciones} options={options} /> : <Cargando />}
                    </div>
                </Tab>
                <Tab eventKey="ocupacion" title="Ocupación hotelera (%)">
                    <div className='row justify-content-md-center'>
                        {statusOcupacion === true ? <Bar data={ocupacion} options={options} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{"Datatur"}</i></p>
                </div>
            </div>
        </div>
    );
}
export default Hospedaje;