import React from 'react';
import Folder from '../../../extras/Folder';

const DocumentosAnalisis = () => {

    const folders = [
        { id: 1, nombre: 'Agricultura', route: '/publicaciones/documentos_analisis/agricultura' },
        { id: 2, nombre: 'Economía', route: '/publicaciones/documentos_analisis/economia' },
        { id: 3, nombre: 'Gobierno', route: '/publicaciones/documentos_analisis/gobierno' },
        { id: 4, nombre: 'Población', route: '/publicaciones/documentos_analisis/poblacion' },
        { id: 5, nombre: 'Conciliación Demográfica de 1950 a 2019 y Proyecciones de Población 2020 a 2070', route: '/publicaciones/documentos_analisis/conciliacion-demografica' },
        { id: 6, nombre: 'Pobreza, rezago social y marginación', route: '/publicaciones/documentos_analisis/pobreza' },
        { id: 7, nombre: 'Salud', route: '/publicaciones/documentos_analisis/salud' },
        { id: 8, nombre: 'Seguridad Pública', route: '/publicaciones/documentos_analisis/seguridad-publica' },
        { id: 9, nombre: 'Tecnología', route: '/publicaciones/documentos_analisis/tecnologia' },
        { id: 10, nombre: 'Zonas de Atención Prioritaria', route: '/publicaciones/documentos_analisis/zap' },
    ];

    //ConcilacionDemografica
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Documentos de Análisis</h2>
                </div>
            </div>
            <hr />
            <br />
            <div className='row'>
                {folders.map((f) => {
                    return (
                        <Folder item={f} key={f.id} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br /><br /> <br /><br /><br /><br /><br /><br /> <br />
        </div>
    );
}
export default DocumentosAnalisis;