import React from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../assets/css/Zoom.css';
import '../../assets/css/Background.css';

const RedesSociales = () => {
    return (
        <div id='redes_sociales'>
            <br /><br /><br /><br />
            <Row clasName='content-lg-right align-middle'>
                <Col md={10} align='right'>
                    <a target="_blank" href="https://www.facebook.com/coeplazac/" rel="noopener noreferrer" >
                        <img className='align-middle zoom-sector' src='/images/facebook.png' alt='Facebook' style={{ width: '50px', height: '70px' }} />
                    </a>
                </Col>
                <Col md={1} align='right'>
                    <a target="_blank" href="https://twitter.com/coeplazac" rel="noopener noreferrer">
                        <img className='align-middle zoom-sector' src='/images/twitter.png' alt='Twitter' style={{ width: '70px', height: '70px' }} />
                    </a>
                    <Col md={1} align='right'></Col>
                </Col>
            </Row>
        </div>
    );
}
export default RedesSociales;