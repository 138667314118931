import React from 'react';
import { Link } from 'react-router-dom';

const Zap2023 = () => {

    const styleEnabled = { background: '#990B0C', color: '#FFFFFF', fontSize: '14', fontWeight: 'bold', cursor: 'pointer' };
    const styleLocalidad = { background: ' #dfd253 ', color: '#FFFFFF', fontSize: '11', fontWeight: 'bold', cursor: 'pointer' };
    const route = 'publicaciones/zap2023/';
    
    const municipios = [
        { id: 2, nombre: 'APULCO', file: '002_0001_APULCO.pdf', localidades: [] },
        { id: 3, nombre: 'ATOLINGA', file: '003_0001_ATOLINGA.pdf', localidades: [] },
        { id: 4, nombre: 'FLORENCIA DE BENITO JUAREZ', file: '004_0001_FLORENCIA_DE_BENITO_JUAREZ.pdf', localidades: [] },
        {
            id: 5, nombre: 'VICTOR ROSALES', file: '005_0001_VICTOR_ROSALES.pdf',
            localidades: [
                { nombre: 'RAMON LOPEZ VELARDE (TORIBIO)', file: '005_0022_RAMON_LOPEZ_VELARDE_(TORIBIO).pdf' },
            ]
        },
        { id: 6, nombre: 'CAÑITAS DE FELIPE PESCADOR', file: '006_0001_CANITAS_DE_FELIPE_PESCADOR.pdf', localidades: []},

        {id: 7, nombre: 'CONCEPCION DEL ORO', file: '007_0001_CONCEPCION_DEL_ORO.pdf', localidades: []},
        {id: 8, nombre: 'CUAUHTEMOC', file: '008_0001_SAN_PEDRO_PIEDRA_GORDA.pdf', localidades: []},
        {id: 9, nombre: 'CHALCHIHUITES', file: '009_0001_CHALCHIHUITES.pdf', localidades: []},
        {
            id: 10, nombre: 'FRESNILLO', file: '010_0001_FRESNILLO.pdf',
            localidades: [
                { nombre: 'ESTACION SAN JOSE', file: '010_0106_ESTACION_SAN_JOSE.pdf'},
                { nombre: 'MIGUEL HIDALGO', file: '010_0135_MIGUEL_HIDALGO.pdf'},
                { nombre: 'PLATEROS', file: '010_0165_PLATEROS.pdf'},
                { nombre: 'LAZARO CARDENAS (RANCHO GRANDE)', file: '010_0183_LAZARO_CARDENAS_(RANCHO_GRANDE).pdf'},
                { nombre: 'RIO FLORIDO', file: '010_0189_RIO_FLORIDO.pdf'},
                { nombre: 'SAN JOSE DE LOURDES', file: '010_0210_SAN_JOSE_DE_LOURDES.pdf'},
            ]
        },
        { id: 11, nombre: 'TRINIDAD GARCIA DE LA CADENA', file: '011_0001_LA_ESTANZUELA_(TRINIDAD_GARCIA_DE_LA_CADENA).pdf', localidades: [] },
        { id: 12, nombre: 'GENARO CODINA', file: '012_0001_GENARO_CODINA.pdf', localidades: [] },
        { id: 13, nombre: 'GENERAL ENRIQUE ESTRADA', file: '013_0001_GENERAL_ENRIQUE_ESTRADA .pdf', localidades: [] },
        { id: 14, nombre: 'GENERAL FRANCISCO R. MURGUIA (NIEVES)', file: '014_0001_GENERAL_FRANCISCO_R_MURGUIA_(NIEVES).pdf', localidades: [] },
        { id: 15, nombre: 'EL PLATEADO DE JOAQUIN AMARO', file: '015_0001_EL_PLATEADO_DE_JOAQUIN_AMARO.pdf', localidades: [] },
        {
            id: 16, nombre: 'GENERAL PANFILO NATERA', file: '016_0001_GENERAL_PANFILO_NATERA.pdf',
            localidades: [
                { nombre: 'SAN JOSE EL SALADILLO (EL SALADILLO)', file: '016_0017_SAN_JOSE_EL_SALADILLO.pdf' },
                { nombre: 'EL SAUCITO (EL HORNO)', file: '016_0025_EL_SAUCITO_(EL_HORNO).pdf' },
            ]
        },
        { id: 17, nombre: 'GUADALUPE', file: '017_0001_GUADALUPE.pdf',
            localidades: [
                { nombre: 'CIENEGUITAS', file: '017_0009_CIENEGUITAS.pdf' },
                { nombre: 'SAN JERONIMO', file: '017_0029_SAN_JERONIMO.pdf' },
                { nombre: 'TACOALECHE', file: '017_0039_TACOALECHE.pdf' },
                { nombre: 'LA ZACATECANA', file: '017_0042_LA_ZACATENA.pdf' },
                { nombre: 'ZOQUITE', file: '017_0043_ZOQUITE.pdf' },
            ]
        },
        { id: 18, nombre: 'HUANUSCO', file: '018_0001_HUANUSCO.pdf', localidades: [] },
        { id: 19, nombre: 'JALPA', file: '019_0001_JALPA.pdf', localidades: [] },
        { id: 20, nombre: 'JEREZ', file: '020_0001_JEREZ.pdf', localidades: [] },
        { id: 21, nombre: 'JIMENEZ DEL TEUL', file: '021_0001_JIMENEZ_DEL_TEUL.pdf', localidades: [] },
        { id: 22, nombre: 'JUAN ALDAMA', file: '022_0001_JUAN_ALDAMA.pdf', localidades: [] },
        {
            id: 24, nombre: 'LORETO', file: '024_0001_LORETO.pdf',
            localidades: [
                { nombre: 'SAN MARCOS', file: '024_0035_SAN_MARCOS.pdf' },
            ]
        },
        { id: 25, nombre: 'LUIS MOYA', file: '025_0001_LUIS_MOYA.pdf', localidades: [] },
        { id: 28, nombre: 'MEZQUITAL DEL ORO', file: '028_0001_MEZQUITAL_DEL_ORO.pdf', localidades: [] },
        { id: 29, nombre: 'MIGUEL AUZA', file: '029_0001_MIGUEL_AUZA.pdf', localidades: [] },
        { id: 30, nombre: 'MOMAX', file: '030_0001_MOMAX.pdf', localidades: [] },
        { id: 31, nombre: 'MONTE ESCOBEDO', file: '031_0001_MONTE_ESCOBEDO.pdf', localidades: [] },
        { id: 32, nombre: 'MORELOS', file: '032_0001_MORELOS.pdf',
        localidades: [
            { nombre: 'HACIENDA NUEVA', file: '032_0011_HACIENDA_NUEVA.pdf' },
        ]},
        { id: 34, nombre: 'NOCHISTLAN DE MEJIA', file: '034_0001_NOCHISTLAN_DE_MEJIA.pdf', localidades: [] },
        { id: 35, nombre: 'NORIA DE ANGELES', file: '035_0001_NORIA_DE_ANGELES.pdf', localidades: [] },
        { id: 36, nombre: 'OJOCALIENTE', file: '036_0001_OJOCALIENTE.pdf', localidades: [] },
        { id: 37, nombre: 'PANUCO', file: '037_0001_PANUCO.pdf', localidades: [
            { nombre: 'POZO DE GAMBOA', file: '037_0016_POZO_DE_GAMBOA.pdf' },
            { nombre: 'SAN ANTONIO DEL CIPRES', file: '037_0021_SAN_ANTONIO_DEL_CIPRES.pdf' },
        ]},
        { id: 38, nombre: 'PINOS', file: '038_0001_PINOS.pdf', localidades: [
            { nombre: 'PEDREGOSO', file: '038_0125_PEDREGOSO.pdf' },
        ]},
        { id: 39, nombre: 'RIO GRANDE', file: '039_0001_RIO_GRANDE.pdf', localidades: [
            { nombre: 'LORETO (RIO GRANDE)', file: '039_0020_LORETO_(RIO_GRANDE).pdf' },
        ]},
        { id: 40, nombre: 'SAIN ALTO', file: '040_0001_SAIN_ALTO.pdf', localidades: [] },
        { id: 41, nombre: 'EL SALVADOR', file: '041_0001_EL_SALVADOR.pdf', localidades: [] },
        { id: 42, nombre: 'SOMBRERETE', file: '042_0001_SOMBRERETE.pdf', localidades: [
            { nombre: 'COLONIA GONZALEZ ORTEGA', file: '042_0042_COLONIA_GONZALEZ_ORTEGA.pdf',  },
            { nombre: 'CHARCO BLANCO', file: '042_0176_CHARCO_BLANCO.pdf',  },
        ]},
        { id: 43, nombre: 'SUSTICACAN', file: '043_0001_SUSTICACAN.pdf', localidades: [] },
        { id: 44, nombre: 'TABASCO', file: '044_0001_TABASCO.pdf', localidades: [] },
        { id: 46, nombre: 'TEPETONGO', file: '046_0001_TEPETONGO.pdf', localidades: [] },
        { id: 47, nombre: 'TEUL DE GONZALEZ ORTEGA', file: '047_0001_TEUL_DE_GONZALEZ_ORTEGA.pdf', localidades: [] },
        { id: 48, nombre: 'TLALTENANGO DE SANCHEZ ROMAN', file: '048_0001_TLALTENANGO_DE_SANCHEZ_ROMAN.pdf', localidades: [] },
        { id: 49, nombre: 'VALPARAISO', file: '049_0001_VALPARAISO.pdf', localidades: [] },
        { id: 50, nombre: 'VETAGRANDE', file: '050_0001_VETAGRANDE.pdf', localidades: [
            { nombre: 'SAUCEDA DE LA BORDA', file: '050_0011_SAUCEDA_DE_LA_BORDA.pdf' },
        ]},
        { id: 51, nombre: 'VILLA DE COS', file: '051_0001_VILLA_DE_COS.pdf', localidades: [
            { nombre: 'GONZALEZ ORTEGA (BANON)', file: '051_0008_GONZALEZ_ORTEGA_(BANON).pdf' },
            { nombre: 'CHUPADEROS', file: '051_0024_CHUPADEROS.pdf' },
            { nombre: 'CHAPARROSA', file: '051_0025_CHAPARROSA.pdf' },
        ]},
        { id: 52, nombre: 'VILLA GARCIA', file: '052_0001_VILLA_GARCIA.pdf', localidades: [] },
        { id: 53, nombre: 'VILLA GONZALEZ ORTEGA', file: '053_0001_VILLA_GONZALEZ_ORTEGA.pdf', localidades: [
            { nombre: 'ESTANCIA DE ANIMAS', file: '053_0015_ESTANCIA_DE_ANIMAS.pdf' },
        ]},
        { id: 54, nombre: 'VILLA HIDALGO', file: '054_0001_VILLA_HIDALGO.pdf', localidades: [] },
        { id: 55, nombre: 'VILLANUEVA', file: '055_0001_VILLANUEVA.pdf', localidades: [
            {  nombre: 'MALPASO', file: '055_0042_MALPASO.pdf' },
        ]},
        { id: 56, nombre: 'ZACATECAS', file: '056_0001_ZACATECAS.pdf', localidades: [] },
        { id: 57, nombre: 'TRANCOSO', file: '057_0001_TRANCOSO.pdf', localidades: [] },
        { id: 58, nombre: 'SANTA MARIA DE LA PAZ', file: '058_0001_SANTA_MARIA_DE_LA_PAZ.pdf', localidades: [] },
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Zonas de Atención Prioritaria 2023</h2>
                </div>
            </div>
            <hr />
            {municipios.map((mun) => {
                return (
                    <React.Fragment key={mun.id}>
                        <div className='row'>
                            <div className='col-md-11'>
                                <h3>
                                    <Link to={`/pdf/${route}${mun.file}`} data-tip data-for={`${route}${mun.file}`} style={{ color: '#000000', textDecoration: 'none' }}>
                                        {mun.nombre}
                                    </Link>
                                </h3>
                            </div>
                            <div className='col-md-1'>
                                <div className='col-md-6'></div>
                                <div className='col-md-6 text-center' style={styleEnabled}>
                                    {mun.file &&
                                        <Link to={`/pdf/${route}${mun.file}`} data-tip data-for={`${route}${mun.file}`} style={{ color: '#FFF', textDecoration: 'none' }}>
                                            <span><small> PDF</small></span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>

                        {mun.localidades.map((loc) => {
                            return (
                                <div className='row' >
                                    <div className='col-md-11'>
                                        <h5>
                                            <Link to={`/pdf/${route}${loc.file}`} data-tip data-for={`${route}${loc.file}`} style={{ color: '#b8b8b8', textDecoration: 'none' }}>
                                                {loc.nombre}
                                            </Link>
                                        </h5>
                                    </div>
                                    <div className='col-md-1'>
                                        <div className='col-md-6'></div>
                                        <div className='col-md-6 text-center' style={styleLocalidad}>
                                            <Link to={`/pdf/${route}${loc.file}`} data-tip data-for={`${route}${loc.file}`} style={{ color: '#FFF', textDecoration: 'none' }}>
                                                <span><small> PDF</small></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <hr />
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default Zap2023;