import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Tecnologia = () => {

    const navigate = useNavigate();
    
    const images = [
        { id: 1, name: 'Ampliacion de la cobertura de Internet en el Estado de Zacatecas', image: 'publicaciones/documentos_analisis/tecnologia/COBERTURA-DE-INTERNET.png', file: 'publicaciones/documentos_analisis/tecnologia/COBERTURA-DE-INTERNET.pdf', type: 'PDF' },
        { id: 2, name: 'Zacatecas ENDUTIH 2018', image: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac2018_.png', file: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac2018_.pdf', type: 'PDF' },
        { id: 3, name: 'Zacatecas ENDUTIH 2020', image: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac2020_.png', file: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac2020_.pdf', type: 'PDF' },
        { id: 4, name: 'Zacatecas ENDUTIH 2021', image: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac2021_.png', file: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac2021_.pdf', type: 'PDF' },
        { id: 5, name: 'Zacatecas ENDUTIH 2022', image: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac22.png', file: 'publicaciones/documentos_analisis/tecnologia/Endutih_Zac22.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Tecnología</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Tecnologia;