import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import Hab from '../layouts/Habitantes';
import clienteAxios from '../../config/clientAxios';

const Habitantes = () => {

    const [cifras, setCifras] = useState({
        habitantes_cifra: 0,
        habitantes_descripcion: '',
        empleos_cifra: 0,
        empleos_descripcion: '',
        superficie_cifra: 0,
        superficie_descripcion: '',
        pea_cifra: 0,
        pea_descripcion: ''
    });
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        const getCifras = async () => {
            await clienteAxios.get(`banner/cifras`, { timeout: 30000 })
                .then(response => {
                    setStatus(true);
                    console.log(response.data.cifras);
                    setCifras(response.data.cifras);
                }).catch(error => {
                    setStatus(false);
                    console.error(error);
                });
        }
        getCifras();
    }, []);

    return (
        <div className='shadow mb-2 rounded' style={{ background: `#730E10`, marginBottom: `1.45rem`, }}>
            <br></br>
            { <Row>
                <Hab
                    cifra={cifras.habitantes_cifra}
                    title={cifras.habitantes_descripcion}
                />
                <Hab
                    cifra={cifras.empleos_cifra}
                    title={cifras.empleos_descripcion}
                />
                <Hab
                    cifra={cifras.superficie_cifra}
                    title={cifras.superficie_descripcion}
                />
                <Hab
                    cifra={cifras.pea_cifra}
                    title={cifras.pea_descripcion}
                />
            </Row>}
        </div>
    );
}
export default Habitantes;