import React, { useEffect, useState } from 'react';
import clienteAxios from '../../../../config/clientAxios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Cargando from '../../../extras/Cargando';
import { Tabs, Tab } from 'react-bootstrap';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

const VisitantesZonaArq = () => {
    const [statusTipo, setStatusTipo] = useState(undefined);
    const [tipo, setTipo] = useState({ "datasets": [], "labels": [] });
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    };
    const [key, setKey] = useState('tipo');
    useEffect(() => {
        const getTipo = async () => {
            await clienteAxios.get(`cieg/turismo/visitantes_zonas_arqueologicas/tipo`, { timeout: 30000 })
                .then(response => {
                    setStatusTipo(true);
                    setTipo(response.data.datasets);
                }).catch(error => {
                    setTipo(false);
                    console.error(error);
                });
        }
        getTipo();
    }, []);
    return (
        <div className='container'>
            <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3">
                <Tab eventKey="tipo" title="Tipo Visitantes">
                    <div className='row justify-content-md-center'>
                        {statusTipo === true ? <Bar data={tipo} options={options} /> : <Cargando />}
                    </div>
                </Tab>
            </Tabs>
            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{"Instituto Nacional de Antropología e Historia"}</i></p>
                </div>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Nota: </strong><i>{"Para el año 2023 el dato es al mes de julio."}</i></p>
                </div>
            </div>
        </div>
    );
}
export default VisitantesZonaArq;