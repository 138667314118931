import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Zap = () => {

    const navigate = useNavigate();
    
    const images = [
        { id: 1, name: '2023', image: 'publicaciones/documentos_analisis/zap/zap2023_Zac.png', file: 'publicaciones/documentos_analisis/zap/zap2023_Zac.pdf', type: 'PDF' },
        { id: 2, name: '2024', image: 'publicaciones/documentos_analisis/zap/Zap2024_Zac.png', file: 'publicaciones/documentos_analisis/zap/Zap2024_Zac.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Zonas de Atención Prioritaria</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Zap;