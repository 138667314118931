import React from 'react';
import File from '../../../extras/File';

import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ConcilacionDemografica = () => {

    const navigate = useNavigate();
    
    const images = [
       { id: 1, name: '0_Pob_Inicio_1950_2070', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/0_Pob_Inicio_1950_2070.xlsx', type: 'XLS'},
       { id: 2, name: '0_Pob_Mitad_1950_2070', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/0_Pob_Mitad_1950_2070.xlsx', type: 'XLS'},
       { id: 3, name: '1_Defunciones_1950_2070', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/1_Defunciones_1950_2070.xlsx', type: 'XLS'},
       { id: 4, name: '2_mig_inter_quinquen_proyecciones', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/2_mig_inter_quinquen_proyecciones.xlsx', type: 'XLS'},
       { id: 5, name: '3_mig_interest_quinquenal_proyeccione', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/3_mig_interest_quinquenal_proyeccione.xlsx', type: 'XLS'},
       { id: 6, name: '4_Tasas_Especificas_Fecundidad_proyecciones.', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/4_Tasas_Especificas_Fecundidad_proyecciones.xlsx', type: 'XLS'},
       { id: 7, name: '5_Indicadores_demográficos_proyecciones', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/5_Indicadores_demográficos_proyecciones.xlsx', type: 'XLS'},
       { id: 8, name: '6_Esperanza_Vida_Nacer_1950_2070', image: 'publicaciones/documentos_analisis/concilacion-demografica/PORTADA_BASE_DE_DATOS.jpg', file: 'publicaciones/documentos_analisis/concilacion-demografica/6_Esperanza_Vida_Nacer_1950_2070.xlsx', type: 'XLS'},
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Conciliación Demográfica de 1950 a 2019 y Proyecciones de Población 2020 a 2070</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-lg-2' style={{ textAlign: 'left' }}>
                    <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </button>
                </div>
                <div className='col-lg-10'></div>
            </div>
            <br/>
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default ConcilacionDemografica;