import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import SectorLink from '../extras/SectorLink';
import Sistemas from './Sistemas';
import SitiosInteres from './SitiosInteres';
import Habitantes from './Habitantes';
import Carrusel from './Carrusel';
import RedesSociales from './RedesSociales';
import Contacto from '../cieg/Contacto';

const ZacatecasEnCifras = () => {
    return (
        <React.Fragment>
            <Carrusel />
            <Habitantes />
            <Sistemas />
            <div id='zacencifras' style={{ background: `#B64A3E` }}>
                <br />
                <Container align='center' >
                    <p className="text-center"><h1><font color='#FFFFFF'>Zacatecas en Cifras</font></h1></p>
                    <Row>
                        <Col>
                            <SectorLink route={'demografia'} src={'demografia.png'} name={'Demografía'} />
                        </Col>
                        <Col>
                            <SectorLink route={'migracion'} src={'migracion.png'} name={'Migración'} />
                        </Col>
                        <Col>
                            <SectorLink route={'ocupacion-y-empleo'} src={'empleo.png'} name={'Ocupación y empleo'} />
                        </Col>
                        
                    </Row>
                    <Row>
                        {/*<Col><SectorLink route={'salud'} src={'salud.png'} name={'Salud'} /></Col>*/}
                        <Col>
                            <SectorLink route={'mineria'} src={'mineria.png'} name={'Minería'}
                            />
                        </Col>
                        <Col>
                            <SectorLink route={'turismo'} src={'turismo.png'} name={'Turismo'} />
                        </Col>
                        <Col>
                            <SectorLink route={'agricultura_ganaderia'} src={'agricultura.png'} name={'Agricultura y Ganadería'} />
                        </Col>
                    </Row>
                        <Row>
                            <Col><SectorLink route={'educacion'} src={'educacion.png'} name={'Educación'} /></Col>
                            <Col><SectorLink route={'economia'} src={'economia.png'} name={'Economía'} /></Col>
                            <Col><SectorLink route={'seguridad_gobierno'} src={'delitos.png'} name={'Seguridad y Gobierno'} /></Col>
                        </Row>
                </Container>
                <br />
            </div>
            <RedesSociales />
            <SitiosInteres />
            <Contacto />
        </React.Fragment>

    );
}
export default ZacatecasEnCifras;