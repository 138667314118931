import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Vuelos from './Vuelos';
import Pasajeros from './Pasajeros';
import VisitantesMuseos from './VisitantesMuseos';
import VisitantesZonaArq from './VisitantesZonaArq';
import Hospedaje from './Hospedaje';

const Turismo = () => {
    const [show, setShow] = useState('vue');
    const option = {
        vue: 'Llegadas de vuelos al aeropuerto Internacional “Leobardo C. Ruiz” ',
        pas: ' Llegadas de pasajeros al aeropuerto Internacional “Leobardo C. Ruiz” ',
        mus: 'Visitantes a museos',
        zoar: 'Visitantes a zonas arqueológicas',
        hos: 'Hospedaje'
    };
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Turismo</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'vue' && 'active'} `} onClick={() => setShow('vue')}>{option['vue']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pas' && 'active'} `} onClick={() => setShow('pas')}>{option['pas']}</button>
                </div>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'mus' && 'active'} `} onClick={() => setShow('mus')}>{option['mus']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'zoar' && 'active'} `} onClick={() => setShow('zoar')}>{option['zoar']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'hos' && 'active'} `} onClick={() => setShow('hos')}>{option['hos']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'vue' && <Vuelos />}
                {show === 'pas' && <Pasajeros />}
                {show === 'mus' && <VisitantesMuseos />}
                {show === 'zoar' && <VisitantesZonaArq />}
                {show === 'hos' && <Hospedaje />}
            </div>
        </React.Fragment>
    );
}
export default Turismo;