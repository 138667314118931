import React from 'react';
import { useState } from 'react';
import Graficas from './general/General';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Economia = () => {

    const [show, setShow] = useState('ieg');

    const option = {
        ieg: 'Inversión Extranjera Directa (millones de dólares).',
        pib: 'Producto Interno Bruto.',
        itaee: 'Indicador Trimestral de la Actividad Económica Estatal',
        exportacion: 'Exportación de mercancias'
    };

    const info = {
        nota: "No se dispone de información para determinar si se realizan exportaciones en el subsector correspondiente,\n N.S. El monto es menor a 500 dólares estadounidenses,\nC  El 31 de diciembre de 2021 se publica el ajuste de la información del subsector 111 Agricultura, para el periodo correspondiente al tercer trimestre de 2020, debido a la actualización de la información fuente.",
        fuente: " INEGI."
    };

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Economía</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'ieg' && 'active'} `} onClick={() => setShow('ieg')}>{option['ieg']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pib' && 'active'} `} onClick={() => setShow('pib')}>{option['pib']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'exportacion' && 'active'} `} onClick={() => setShow('exportacion')}>{option['exportacion']}</button>
                </div>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'itaee' && 'active'} `} onClick={() => setShow('itaee')}>{option['itaee']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'ieg' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'pib' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'itaee' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
                {show === 'exportacion' && <Graficas route={show} nota={info.nota} fuente={info.fuente} />}
            </div>

            {show === 'pib' &&
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Nota: </strong><i>{"Para el año 2020 la cifras son revisadas y para el 2021 son cifras preliminares."}</i></p>
                </div>
            }


        </React.Fragment>
    );
}

export default Economia;