import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../../assets/css/Zoom.css';

import ReCAPTCHA from "react-google-recaptcha";

const Contacto = () => {
    const recaptchaRef = React.createRef();
    return (
        <footer>
            <div id='footer' style={{ background: '#7D878E' }}>
                <br></br>
                <Row>
                    <Col align='center'>
                        <font color='white'><h3 className='center'>Contacto</h3></font>
                    </Col>
                </Row>
                <br />
                <Row className="justify-content-md-center">
                    <Col align='center' md={6}>
                        <p>
                            <font color='white'>
                                <h6>
                                    <FontAwesomeIcon icon={faLocationDot} size="2rem"></FontAwesomeIcon>&nbsp;
                                    Circuito Cerro del Gato, Edificio H 2do. Piso s/n,<br /> Colonia Ciudad Administrativa, C.P. 98160, Zacatecas, Zac.
                                </h6>
                            </font>
                        </p>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.6632687866613!2d-102.61528838451466!3d22.777874531143613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86824e0687c00001%3A0x4a131cd3c5135fde!2sEdificio%20H%20Ciudad%20Gobierno%20zacatecas!5e0!3m2!1ses-419!2smx!4v1604888582296!5m2!1ses-419!2smx" width="95%" height="85%" frameBorder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" title='mapa_frame'></iframe>
                    </Col>
                    <Col align='center' md={5} >
                        <p><font color='white'><h5>Contáctanos</h5></font></p>
                        <Form method="post" action="#">
                            <Form.Group>
                                <font color='white'><h6>Nombre (requerido)</h6></font>
                                <Form.Control type="text" name="name" id="name" />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <font color='white'><h6>Email (requerido)</h6></font>
                                <Form.Control type="email" />
                            </Form.Group>
                            <Form.Group>
                                <font color='white'><h6>Asunto</h6></font>
                                <Form.Control type="text" name="subject" id="subject" />
                            </Form.Group>
                            <Form.Group>
                                <font color='white'><h6>Mensaje</h6></font>
                                <Form.Control as="textarea" rows={6} id="message" />
                            </Form.Group>
                            <br />
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdgGNIeAAAAADRpBSxnCd5_h_oMb2J0SzwSrJmL"
                                onChange={() => console.log(recaptchaRef.current.getValue())} />
                                <br/>
                            <div className="d-grid gap-2">
                                <Button className='btn btn-primario' size='lg' type="submit">Enviar</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <br></br>
            </div>
            {/*
            <div style={{ background: '#454D53', marginBottom: '1rem' }}>
                <Row>
                    <Col align='center'>
                        <font color="white">Gobierno del Estado de Zacatecas 2021 - 2027</font>
                    </Col>
                </Row>
            </div>
            */}
        </footer>
    );
}

export default Contacto;