import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Barras from './Barras';
import Nivel from './Nivel';

const Pea = ({ route, fuente }) => {
    const [key, setKey] = useState('total');

    useEffect(() => {
        if (route == 'alumnos_nivel') {
            setKey('inicial');
        }
    }, []);

    return (
        <div className='container'>

            {route != 'alumnos_nivel' ?
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3">
                    <Tab eventKey="total" title="Total">
                        <div className='row justify-content-md-center'>
                            <Barras route={route} />
                        </div>
                    </Tab>
                </Tabs> :
                <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3">
                    <Tab eventKey="inicial" title="Educación inicial">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'inicial'} />
                        </div>
                    </Tab>
                    <Tab eventKey="preescolar" title="Educación preescolar">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'preescolar'} />
                        </div>
                    </Tab>
                    <Tab eventKey="primaria" title="Educación primaria">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'primaria'} />
                        </div>
                    </Tab>
                    <Tab eventKey="secundaria" title="Educación secundaria">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'secundaria'} />
                        </div>
                    </Tab>
                    <Tab eventKey="especial" title="Educación especial">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'especial'} />
                        </div>
                    </Tab>
                    <Tab eventKey="media_superior" title="Educación media superior">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'media_superior'} />
                        </div>
                    </Tab>
                    <Tab eventKey="normal" title="Educación normal">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'normal'} />
                        </div>
                    </Tab>
                    <Tab eventKey="superior" title="Educación superior">
                        <div className='row justify-content-md-center'>
                            <Nivel route={route} nivel_educativo={'superior'} />
                        </div>
                    </Tab>
                </Tabs>
            }

            <div className='row'>
                <div className='col-lg-8' style={{ textAlign: 'left', fontSize: '12px' }}>
                    <p><strong>Fuente: </strong><i>{fuente}</i></p>
                </div>
            </div>
        </div>
    );
}
export default Pea;