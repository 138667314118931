import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Densidad_poblacional from './Densidad_poblacional';
import EspranzaVida from './EsperanzaVida';
import Idh from './Idh';
import RazonDependencia from './RazonDependencia';
import Pobreza from './Pobreza';
import Poblacion from './Poblacion';
import Marginacion from './Marginacion';

const Demografia = () => {

    const [show, setShow] = useState('depob');

    const option = {
        depob: 'Densidad de Población',
        esvi: 'Esperanza de Vida',
        idh: 'Índice de desarrollo humano',
        mar: 'Marginación',
        pob: 'Pobreza',
        rdep: 'Razón de Dependencia',
        pos: 'Población por Sexo'
    };

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Demografía</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'depob' && 'active'} `} onClick={() => setShow('depob')}>{option['depob']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'esvi' && 'active'} `} onClick={() => setShow('esvi')}>{option['esvi']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'idh' && 'active'} `} onClick={() => setShow('idh')}>{option['idh']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'mar' && 'active'} `} onClick={() => setShow('mar')}>{option['mar']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pob' && 'active'} `} onClick={() => setShow('pob')}>{option['pob']}</button>
                </div>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'rdep' && 'active'} `} onClick={() => setShow('rdep')}>{option['rdep']}</button>
                    
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'pos' && 'active'} `} onClick={() => setShow('pos')}>{option['pos']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'depob' && <Densidad_poblacional />}
                {show === 'esvi' && <EspranzaVida />}
                {show === 'idh' && <Idh />}
                {show === 'pob' && <Pobreza />}
                {show === 'rdep' && <RazonDependencia />}
                {show === 'pos' && <Poblacion />}
                {show === 'mar' && <Marginacion />}
                
            </div>
        </React.Fragment>
    );
}

export default Demografia;