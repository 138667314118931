import React from 'react';
import File from '../extras/File';

const Juridico = () => {

    const images = [
        { name: 'Ley del SNIEG.', image: 'marco_juridico/Lsnieg.jpg', file: 'marco_juridico/LSNIEG.pdf', type: 'PDF' },
        { name: 'Reglas de los comités.', image: 'marco_juridico/Reglas.jpg', file: 'marco_juridico/Reglas_CTE-081216.pdf', type: 'PDF' },
        { name: 'Ley de Planeación.', image: 'marco_juridico/Ley.jpg', file: 'marco_juridico/Ley-de-Planeacion-para-el-Estado-de-Zacatecas-y-sus-municipios.pdf', type: 'PDF' },
        { name: 'Convenio CEIEG.', image: 'marco_juridico/Convenio.jpg', file: 'marco_juridico/Convenio-Centro-de-Informacion.pdf', type: 'PDF' },
        { name: 'Convenio para la constitución y operación del CEIEG.', image: 'marco_juridico/Convenio_CEIEG2023.png', file: 'marco_juridico/Convenio_CEIEG2023.pdf', type: 'PDF' }
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Marco Jurídico</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}

export default Juridico;