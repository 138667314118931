import React from 'react';
import File from '../../extras/File';

const Estadisticas = () => {
    const images = [
        {
            name: 'Norma Técnica para la Clasificación Nacional \n de Delitos para Fines Estadísticos.',
            image: `normatividad/tecnicas_estrategicas/Tecnica-Delitos-con-Fines-Estadisticos.png`, file: `normatividad/tecnicas_estrategicas/Tecnica-Delitos-con-Fines-Estadisticos.pdf`,
            type: 'PDF'
         },
        {
            name: 'Norma Técnica para la Elaboración de Metadatos para proyectos de generación de Información Estadística Básica y de los componentes estadísticos derivados de proyectos geográficos.',
            image: `normatividad/tecnicas_estrategicas/Elaboracion-metadatos-Proyectos-Estadisticos.png`, file: `normatividad/tecnicas_estrategicas/Elaboracion-metadatos-Proyectos-Estadisticos.pdf`,
            type: 'PDF'
        },
        {
            name: 'Norma Técnica para la Generación de Estadística Básica.',
            image: `normatividad/tecnicas_estrategicas/Tecnica-para-la-Generacion-de-Estadistica-Basica.png`, file: `normatividad/tecnicas_estrategicas/Tecnica-para-la-Generacion-de-Estadistica-Basica.pdf`,
            type: 'PDF'
        }
    ];
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Normas Técnicas Estadísticas</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i}/>
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}
export default Estadisticas;