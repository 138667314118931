import React from 'react';
import Folder from '../../../extras/Folder';

const Croquis = () => {
    
    const folders = [
        { id: 1, nombre: 'Tamaño Carta', route: '/publicaciones/croquis_municipales/carta' },
        { id: 2, nombre: 'Tamaño Doble Carta', route: '/publicaciones/croquis_municipales/doble-carta' }
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Croquis Municipales</h2>
                </div>
            </div>
            <hr />
            <br />
            <div className='row'>
                {folders.map((f) => {
                    return (
                        <Folder item={f} />
                    );
                })}
            </div>
            <br /><br /><br /><br /><br /><br /> <br /><br /><br /><br /><br /><br /> <br />
        </div>
    );
}

export default Croquis;