import React from 'react';
import File from '../../extras/File';

const InfoGeoReg = () => {
    
    const images = [
        { id: 1, name: 'Región 1 Centro', image: 'publicaciones/informacion_geografica_regional/REGION_1_CENTRO_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_1_CENTRO_GEOS.pdf', type: 'PDF' },
        { id: 2, name: 'Región 2 Centro-Sur', image: 'publicaciones/informacion_geografica_regional/REGION_2_CENTRO_SUR_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_2_CENTRO_SUR_GEOS.pdf', type: 'PDF' },
        { id: 3, name: 'Región 3 Suroeste', image: 'publicaciones/informacion_geografica_regional/REGION_3_SUROESTE_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_3_SUROESTE_GEOS.pdf', type: 'PDF' },
        { id: 4, name: 'Región 4 Oeste', image: 'publicaciones/informacion_geografica_regional/REGION_4_OESTE_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_4_OESTE_GEOS.pdf', type: 'PDF' },
        { id: 5, name: 'Región 5 Noreste', image: 'publicaciones/informacion_geografica_regional/REGION_5_NOROESTE_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_5_NOROESTE_GEOS.pdf', type: 'PDF' },
        { id: 6, name: 'Región 6 Norte', image: 'publicaciones/informacion_geografica_regional/REGION_6_NORTE_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_6_NORTE_GEOS.pdf', type: 'PDF' },
        { id: 7, name: 'Región 7 Sureste', image: 'publicaciones/informacion_geografica_regional/REGION_7_SURESTE_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_7_SURESTE_GEOS.pdf', type: 'PDF' },
        { id: 8, name: 'Región 8 Sur', image: 'publicaciones/informacion_geografica_regional/REGION_8_SUR_GEOS.png', file: 'publicaciones/informacion_geografica_regional/REGION_8_SUR_GEOS.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Información Geográfica Regional</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default InfoGeoReg;