import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import GradoMigracion from './GradoMigracion';
import Remesas from './Remesas';
import TasaMigracion from './TasaMigracion';

const Migracion = () => {

    const [show, setShow] = useState('rem');

    const option = {
        gimg: 'Grado de Intensidad Migratoria',
        tas: 'Tasa de Migración',
        rem: 'Remesas (Millones de Dolares)',
    };
    
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-lg-4 mt-3' style={{ textAlign: 'center' }}>
                    <Link to={'/'} className='btn btn btn-outline-secondary'>
                        <FontAwesomeIcon icon={faArrowLeft} /> Regresar
                    </Link>
                </div>
                <div className='col-lg-4'>
                    <h1 style={{ textAlign: 'center' }}>Migración</h1>
                    <h3 style={{ textAlign: 'center' }}>{option[show]}</h3>
                </div>
                <div className='col-lg-4'></div>
            </div>
            <div className='row'>
                <div className='col-lg-12' style={{ textAlign: 'center' }}>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'rem' && 'active'} `} onClick={() => setShow('rem')}>{option['rem']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'tas' && 'active'} `} onClick={() => setShow('tas')}>{option['tas']}</button>
                    <button className={`btn btn-primary btn-sm me-1 mb-1 ${show === 'gimg' && 'active'} `} onClick={() => setShow('gimg')}>{option['gimg']}</button>
                </div>
            </div>
            <br />
            <div className='container'>
                {show === 'gimg' && <GradoMigracion />}
                {show === 'rem' && <Remesas />}
                {show === 'tas' && <TasaMigracion />}
            </div>
        </React.Fragment>
    );
}

export default Migracion;