import React from 'react';
import File from '../../extras/File';

const Geografica = () => {
    const images = [
        {
            name: 'Norma Técnica para la Generación, Captación e Integración de Datos Catastrales y Registrales con fines estadísticos y geográficos.',
            image: `normatividad/tecnicas_geograficas/Norma_Tecnica_Datos_Catastrales.png`, file: `normatividad/tecnicas_geograficas/Norma_Tecnica_Datos_Catastrales.pdf`,
            type: 'PDF'
        },
        {
            name: 'Norma Técnica para el Sistema Geodésico Nacional.',
            image: `normatividad/tecnicas_geograficas/Norma_Tecnica_para_el_Sistema_Geodesico_Nacional.png`, file: `normatividad/tecnicas_geograficas/Norma_Tecnica_para_el_Sistema_Geodesico_Nacional.pdf`,
            type: 'PDF'
        },
        {
            name: 'Norma Técnica de Estándares de Exactitud Posicional.',
            image: `normatividad/tecnicas_geograficas/Norma_Tecnica_de_Estandares_de_Exactitud_Posicional.png`, file: `normatividad/tecnicas_geograficas/Norma_Tecnica_de_Estandares_de_Exactitud_Posicional.pdf`,
            type: 'PDF'
        },
        {
            name: 'Norma Técnica para la elaboración de Metadatos Geográficos.',
            image: `normatividad/tecnicas_geograficas/Norma_Tecnica_para_la_elaboracion_de_Metadatos_Geograficos.png`, file: `normatividad/tecnicas_geograficas/Norma_Tecnica_para_la_elaboracion_de_Metadatos_Geograficos.pdf`,
            type: 'PDF'
        },
        {
            name: 'Norma Técnica sobre Domicilios Geográficos.',
            image: `normatividad/tecnicas_geograficas/Norma_Tecnica_sobre_Domicilios_Geograficos.png`, file: `normatividad/tecnicas_geograficas/Norma_Tecnica_sobre_Domicilios_Geograficos.pdf`,
            type: 'PDF'
        }
    ];
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Normas Técnicas Geográficas</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i}/>
                    );
                })}
            </div>
            <br /><br /><br /><br /><br />
        </div>
    );
}
export default Geografica;