import React from 'react';
import File from '../../extras/File';

const Sesiones = () => {
    
    const images = [
        
        
        { id: 1, name: 'Minuta 12 de Mayo 2022.', image: 'ceieg/sesiones_ordinarias/Minuta12mayo2022.png', file: 'ceieg/sesiones_ordinarias/Minuta12mayo2022.pdf', type: 'PDF' },
        { id: 2, name: 'Minuta 01 de Septiembre 2022.', image: 'ceieg/sesiones_ordinarias/Minuta01septiembre2022.png', file: 'ceieg/sesiones_ordinarias/Minuta01septiembre2022.pdf', type: 'PDF' },
        { id: 3, name: 'Minuta 01 de Diciembre 2022.', image: 'ceieg/sesiones_ordinarias/Tercera_reunion2022.png', file: 'ceieg/sesiones_ordinarias/Tercera_reunion2022.pdf', type: 'PDF' },
        { id: 4, name: 'Minuta 29 de Agosto 2023.', image: 'ceieg/sesiones_ordinarias/minuta_PrimeraSesion2023.png', file: 'ceieg/sesiones_ordinarias/minuta_PrimeraSesion2023.PDF', type: 'PDF' },
        { id: 5, name: 'Minuta 8 de Diciembre 2023.', image: 'ceieg/sesiones_ordinarias/Minuta-2da-Sesion-Ordinaria-2023-CEIEG.png', file: 'ceieg/sesiones_ordinarias/Minuta-2da-Sesion-Ordinaria-2023-CEIEG.pdf', type: 'PDF' },
    ];
    
    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Sesiones Ordinarias</h2>
                </div>
            </div>
            <hr />
            <div className='row'>
                {images.map((i) => {
                    return (
                        <File doc={i} key={i.id}/>
                    );
                })}
            </div>
            <br /><br/><br/><br/><br/>
        </div>
    );
}

export default Sesiones;