import React from 'react';
import { Link } from 'react-router-dom';

const Zap2022 = () => {

    const styleEnabled = { background: '#990B0C', color: '#FFFFFF', fontSize: '14', fontWeight: 'bold', cursor: 'pointer' };
    const styleLocalidad = { background: ' #dfd253 ', color: '#FFFFFF', fontSize: '11', fontWeight: 'bold', cursor: 'pointer' };
    const route = 'publicaciones/zap2022/';
    
    const municipios = [
        { id: 1, nombre: '2.-Apulco', file: '002_APULCO.pdf', localidades: [] },
        { id: 2, nombre: '4.-Benito Juárez', file: '004_BENITO_JUAREZ.pdf', localidades: [] },
        {
            id: 3, nombre: '5.-Calera de Víctor Rosales', file: '005_0001_VICTOR ROSALES.pdf',
            localidades: [
                { nombre: '1.-Ramón López Velarde', file: '005_0022_RAMON_LOPEZ_VELARDE_(TORIBIO).pdf' }
            ]
        },
        { id: 4, nombre: '6.-Cañitas de Felipe Pescador', file: '006_CANITAS_DE_FELIPE_PESCADOR.pdf', localidades: [] },
        { id: 5, nombre: '7.-Concepción del Oro', file: '007_CONCEPCION_DEL_ORO.pdf', localidades: [] },
        { id: 6, nombre: '8.-Cuauhtémoc', file: '008_CUAUHTEMOC.pdf', localidades: [] },
        { id: 7, nombre: '9.-Chalchihuites', file: '009_CHALCHIHUITES.pdf', localidades: [] },
        {
            id: 8, nombre: '10.-Fresnillo', file: '010_0001_FRESNILLO.pdf',
            localidades: [
                { nombre: '106.-Estación San José', file: '010_0106_ESTACION_SAN_JOSE.pdf' },
                { nombre: '135.-Miguel Hidalgo (Hidalgo)', file: '010_0135_MIGUEL_HIDALGO_(HIDALGO).pdf' },
                { nombre: '165.-Plateros', file: '010_0165_PLATEROS.pdf' },
                { nombre: '183.-Lázaro Cárdenas (Rancho Grande)', file: '010_0183_LAZARO_CARDENAS_(RANCHO_GRANDE).pdf' },
                { nombre: '189.-Río Florido', file: '010_0189_RIO_FLORIDO.pdf' },
                { nombre: '210.-San José de Lourdes', file: '010_0210_SAN_JOSE_DE_LOURDES.pdf' }
            ]
        },

        { id: 9, nombre: '11.-Trinidad García de la Cadena', file: '011_TRINIDAD_GARCIA_DE_LA_CADENA.pdf', localidades: [] },
        { id: 10, nombre: '13.-General Enrique Estrada', file: '013_GENERAL_ENRIQUE_ESTRADA.pdf', localidades: [] },
        {
            id: 11, nombre: '16.-General Pánfilo Natera', file: '016_0001_GENERAL_PANFILO_NATERA.pdf',
            localidades: [
                { nombre: '17.-San José (El Saladillo)', file: '016_0017_SAN_JOSE_EL_SALADILLO_(EL_SALADILLO).pdf' },
                { nombre: '25.-El Saucito (El Horno)', file: '016_0025_EL_SAUCITO_(EL_HORNO).pdf' }
            ]
        },
        {
            id: 12, nombre: '17.-Guadalupe', file: '017_0001_GUADALUPE.pdf',
            localidades: [
                { nombre: '9.-Cieneguitas', file: '017_0009_CIENEGUITAS.pdf' },
                { nombre: '29.-San Jerónimo', file: '017_0029_SAN_JERONIMO.pdf' },
                { nombre: '39.-Tacoaleche', file: '017_0039_TACOALECHE.pdf' },
                { nombre: '42.-La Zacatena', file: '017_0042_LA_ZACATECANA.pdf' },
                { nombre: '43.-Zóquite', file: '017_0043_ZOQUITE.pdf' }
            ]
        },

        { id: 13, nombre: '20.-Jerez', file: '020_JEREZ.pdf', localidades: [] },
        { id: 14, nombre: '21.-Jiménez del Teul', file: '021_JIMENEZ_DEL_TEUL.pdf', localidades: [] },
        { id: 15, nombre: '22.-Juan Aldama', file: '022_JUAN_ALDAMA.pdf', localidades: [] },
        {
            id: 16, nombre: '24.-Loreto', file: '024_0001_LORETO.pdf',
            localidades: [
                { nombre: '35.-San Marcos', file: '024_0035_SAN_MARCOS.pdf' }
            ]
        },
        { id: 17, nombre: '25.-Luis Moya', file: '025_LUIS_MOYA.pdf', localidades:[] },
        {
            id: 18, nombre: '32.-Morelos', file: '032_0001_MORELOS.pdf',
            localidades: [
                { nombre: '11.-Hacienda Nueva', file: '032_0011_HACIENDA_NUEVA.pdf' }
            ]
        },
        { id: 19, nombre: '28.-Mezquital del Oro', file: '028_MEZQUITAL_DEL_ORO.pdf', localidades: [] },
        { id: 20, nombre: '29.-Miguel Auza', file: '029_MIGUEL_AUZA.pdf', localidades: [] },
        { id: 21, nombre: '34.-Nochistlán de Mejía', file: '034_NOCHISTLAN_DE_MEJIA.pdf', localidades: [] },
        { id: 22, nombre: '36.-Ojocaliente', file: '036_OJOCALIENTE.pdf', localidades: [] },
        {
            id: 23, nombre: '37.-Panuco', file: '037_0001_PANUCO.pdf',
            localidades: [
                { nombre: '16.-Pozo de Gamboa', file: '037_0016_POZO_DE_GAMBOA.pdf' },
                { nombre: '21.-San Antonio del Ciprés', file: '037_0021_SAN_ANTONIO_DEL_CIPRES.pdf' },
            ]
        },
        { id: 24, nombre: '38.-Pinos', file: '038_PINOS.pdf', localidades: [] },
        { id: 25, nombre: '41.-El Salvador', file: '041_EL_SALVADOR.pdf', localidades: [] },
        { id: 26, nombre: '43.-Susticacán', file: '043_SUSTICACAN.pdf', localidades: [] },
        {
            id: 27, nombre: '42.-Sombrerete', file: '042_0001_SOMBRERETE.pdf',
            localidades: [
                { nombre: '42.-Colonia González Ortega', file: '0420042_zap_2022' }
            ]
        },
        { id: 28, nombre: '48.-Tlantenango de Sánchez Román', file: '0480001_zap_2022', localidades: [] },
        { id: 29, nombre: '49.-Valparaíso', file: '049_VALPARAISO.pdf', localidades: [] },
        {
            id: 30, nombre: '50.-Vetagrande', file: '050_0001_VETAGRANDE.pdf',
            localidades: [
                { nombre: '11.-Sauceda de la Borda', file: '050_0011_SAUCEDA_DE_LA_BORDA.pdf' }
            ]
        },
        {
            id: 31, nombre: '51.-Villa de Cos', file: '051_0001_VILLA_DE_COS.pdf',
            localidades: [
                { nombre: 'González Ortega (Bañon)', file: '051_0008_GONZALEZ_ORTEGA_(BANON).pdf' },
                { nombre: '24.-Chupaderos', file: '051_0024_CHUPADEROS.pdf' },
                { nombre: '25.-Chaparrosa', file: '051_0025_CHAPARROSA.pdf' }
            ]
        },
        {
            id: 32, nombre: '53.-Villa González Ortega', file: null,
            localidades: [
                { nombre: '15.-Estancia de Ánimas', file: '053_0015_ESTANCIA_DE_ANIMAS.pdf' }
            ]
        },
        { id: 33, nombre: '54.-Villa Hidalgo', file: '054_VILLA_HIDALGO.pdf', localidades: [] },
        {
            id: 34, nombre: '55.-Villanueva', file: '055_0001_VILLANUEVA.pdf',
            localidades: [
                { nombre: '42.-Malpaso', file: '055_0042_MALPASO.pdf' }
            ]
        },
        { id: 36, nombre: '56.-Zacatecas', file: '056_ZACATECAS.pdf', localidades: [] },
        { id: 37, nombre: '57.-Trancoso', file: '057_TRANCOSO.pdf', localidades: [] }
    ];

    return (
        <div className='container'>
            <br />
            <div className='row'>
                <div className='col-lg-12 text-center'>
                    <h2>Zonas de Atención Prioritaria 2022</h2>
                </div>
            </div>
            <hr />
            {municipios.map((mun) => {
                return (
                    <React.Fragment key={mun.id}>
                        <div className='row'>
                            <div className='col-md-11'>
                                <h3>
                                    <Link to={`/pdf/${route}${mun.file}`} data-tip data-for={`${route}${mun.file}`} style={{ color: '#000000', textDecoration: 'none' }}>
                                        {mun.nombre}
                                    </Link>
                                </h3>
                            </div>
                            <div className='col-md-1'>
                                <div className='col-md-6'></div>
                                <div className='col-md-6 text-center' style={styleEnabled}>
                                    {mun.file &&
                                        <Link to={`/pdf/${route}${mun.file}`} data-tip data-for={`${route}${mun.file}`} style={{ color: '#FFF', textDecoration: 'none' }}>
                                            <span><small> PDF</small></span>
                                        </Link>
                                    }
                                </div>
                            </div>
                        </div>
                        
                        {mun.localidades.map((loc) => {
                            return (
                                <div className='row' >
                                    <div className='col-md-11'>
                                        <h5>
                                           <Link to={`/pdf/${route}${loc.file}`} data-tip data-for={`${route}${loc.file}`} style={{ color: '#b8b8b8', textDecoration: 'none' }}>
                                                {loc.nombre}
                                            </Link>
                                        </h5>
                                    </div>
                                    <div className='col-md-1'>
                                        <div className='col-md-6'></div>
                                        <div className='col-md-6 text-center' style={styleLocalidad}>
                                            <Link to={`/pdf/${route}${loc.file}`} data-tip data-for={`${route}${loc.file}`} style={{ color: '#FFF', textDecoration: 'none' }}>
                                                <span><small> PDF</small></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <hr />
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default Zap2022;